import { notification } from "../functions/notification";
import axios from "../utils/axios";
import { auth } from "../firebase/firebase-utils";

export function signIn(credentials) {
	return new Promise(async (resolve, reject) => {
		const { email, password } = credentials;
		try {
			const user = await auth.signInWithEmailAndPassword(email, password);
			resolve(user);
			notification("Success", "You have signed in successfully", "success");
		} catch (err) {
			console.log(err);
			notification("Opps", err.message, "danger");
		}
	});
}

export function signUp(credentials) {
	return new Promise(async (resolve, reject) => {
		const { email, password } = credentials;
		try{
			const user = await auth.createUserWithEmailAndPassword(email, password);
			resolve(user);
			notification("Success", "You have signed up successfully", "success");
		}catch{
			notification("Opps","Something went wrong!", "danger")
		}
	});
}

export function resetPassword(credentials) {
	return new Promise((resolve, reject) => {
		axios
			.post("/api/auth/reset-password", credentials)
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
				reject(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}
