import {firestore} from "../firebase/firebase-utils";

export async function addLocation(location) {
	try {
		await firestore.collection("locations").doc(location).set({name: location}, {merge: true});
		console.log("Location is added!");
	} catch (error) {
		console.log(error);
	}
}
