import moment from "moment";
import {convertDate} from "./convertDate";
import {arrayIsEmpty} from "../functions/arrayIsEmpty";

export function getSalesByMonth(fSales) {
	try {
		if (arrayIsEmpty(fSales)) {
			return {};
		}
		let salesByMonth = {};
		fSales.forEach((sale) => {
			if (sale.status === "Cancelled") return;
			let date = moment(convertDate(sale.date)).format("YYYY-MM");
			date = date.split("-");
			const key = date[0] + date[1];
			if (salesByMonth[key]) {
				salesByMonth[key] = salesByMonth[key] + parseFloat(sale.renNetPrice);
			} else {
				salesByMonth[key] = parseFloat(sale.renNetPrice);
			}
		});
		return salesByMonth;
	} catch (error) {
		console.log(error);
	}
}
