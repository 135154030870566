import { firestore } from '../firebase/firebase-utils';

export async function getProjectFromAtlasProjectName(atlasProjectName) {
	try {
		const snapshot = await firestore.collection('projects').where('atlasProjectName', '==', atlasProjectName).get();
		if (snapshot.empty) {
			return { projectName: '', projectFid: '' };
		} else {
			const project = { projectName: snapshot.docs[0].data().projectName, id: snapshot.docs[0].id };
			return project;
		}
	} catch (error) {
		console.log(error);
	}
}
