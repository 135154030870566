import firebase, {firestore} from "../firebase/firebase-utils";
import {capitaliseName} from "./capitaliseName";
import {notification} from "./notification";

export async function signUp(user) {
	try {
		let {
			email,
			firstName,
			lastName,
			phone,
			displayName,
			chatId,
			immediateUpline,
			immediateUplineFid,
			password,
			location,
		} = user;
		firstName = capitaliseName(firstName);
		lastName = capitaliseName(lastName);
		displayName = capitaliseName(displayName);
		email = email.toLowerCase();

		const idDoc = await firestore.collection("parameters").doc("parameters").get();
		let userId = 1;
		if (idDoc.exists) {
			userId = idDoc.data().userIdCount + 1;
		}

		const data = {
			userId: userId,
			created: new Date(),
			displayName,
			firstName,
			lastName,
			phoneNumber: phone,
			email,
			chatId,
			immediateUpline,
			immediateUplineFid,
			location: location,
		};

		// Create leadsAppSignUps Firestore entry
		const collectionRef = firestore.collection("appSignUps");
		await collectionRef.doc(email).set(data);
		console.log(displayName + " added into Firestore Collection");

		// Create firebase auth user
		await firebase.auth().createUserWithEmailAndPassword(email, password);
		notification(
			"Welcome",
			"You have successfully signed up. Please check your email for the verification link",
			"info"
		);
		const newUser = firebase.auth().currentUser;
		await newUser.updateProfile({
			displayName: displayName,
		});

		await newUser.sendEmailVerification();
		return newUser;
	} catch (err) {
		notification("Opps!", err.message, "danger");
		console.log(err);
		return "error";
	}
}
