import { firestore } from '../firebase/firebase-utils';

export async function getSaleId() {
	try {
		const doc = await firestore.collection('parameters').doc('parameters').get();
		if (doc.exists) {
			return doc.data().numberOfSales + 1;
		} else {
			return 1;
		}
	} catch (error) {
		console.log(error);
	}
}
