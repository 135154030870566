export const numberWithCommas = (x) => {
    if(!x){return '-'}
    let number = parseInt(parseFloat(x));
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export const numberToPercentage = (x) =>{
    return  (x).toString() + '%' 
}

export const stringToNumber = (s) => {
    if(!s){
        return 0
    }
    s = s.split(',').join('');
    return parseFloat(s);
}

export const numberWithDecimals = (x) => {
    const n = parseFloat(x).toFixed(2)
    // var withCommas = Number(n).toLocaleString('en');
    var formattedString= n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return formattedString
}
