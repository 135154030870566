import {firestore} from "../firebase/firebase-utils";
import {getFirestoreData} from "../functions/getFirestoreData";

export async function populateTeamIds() {
	try {
		const users = await getFirestoreData("users");
		const sales = await getFirestoreData("sales");

		const findUplines = (ren) => {
			const uplines = [];
			const getUplines = (ren) => {
				const upline = users.find((user) => user.id === ren.immediateUplineFid);
				if (upline) {
					uplines.push(upline);
					getUplines(upline);
				}
			};
			const first = users.find((user) => user.id === ren.userFid);
			getUplines(first);
			return uplines;
		};

		let salesArray = [];
		sales.forEach(async (sale) => {
			const teamIds = [];
			sale.rens.forEach((ren) => {
				if (ren.userFid) {
					teamIds.push(ren.userFid);
					const uplines = findUplines(ren);
					if (uplines) {
						uplines.forEach((upline) => {
							const found = teamIds.find((id) => id === upline.id);
							if (!found) {
								teamIds.push(upline.id);
							}
						});
					}
				}
			});
			const mappedSale = {saleId: sale.id, teamIds: teamIds};
			salesArray.push(mappedSale);
		});
		console.log(salesArray);

		salesArray.forEach(async (sale) => {
			await firestore.collection("sales").doc(sale.saleId).update({teamIds: sale.teamIds});
			console.log("Updated teamIds in sales");
		});
	} catch (error) {
		console.log(error);
	}
}
