// @ts-nocheck
//1.
import GlobalList from "../components/GlobalList.jsx";
import React, {useState} from "react";
import {useRef} from "react";

//2.
export const ListContext = React.createContext();

//3.
export const ListProvider = ({children}) => {
	const [title, setTitle] = useState();
	const [open, setOpen] = useState(false);
	const [objects, setObjects] = useState([]);
	const [displayName, setDisplayName] = useState();
	const [icon, setIcon] = useState();
	const [addAccount, setAddAccount] = useState(true);
	const [search, setSearch] = useState(false);

	const handleSave = (object) => {
		setOpen(false);
		awaitingPromiseRef.current.resolve(object);
	};

	const awaitingPromiseRef = useRef();

  	// Usage:
	// const response = await openGlobalList(objects, title, displayName, icon, addAccount, search);
	const openGlobalList = async (objects, title, displayName, icon, addAccount, search) => {
		setObjects(objects);
		setTitle(title);
		setDisplayName(displayName);
		setIcon(icon);
		setOpen(true);
		setAddAccount(addAccount);
		setSearch(search);

		return new Promise((resolve, reject) => {
			awaitingPromiseRef.current = {resolve, reject};
		});
	};

	return (
		<ListContext.Provider value={{openGlobalList}}>
			{children}
			<GlobalList
				open={open}
				setOpen={setOpen}
				title={title}
				onSave={handleSave}
				displayName={displayName}
				objects={objects}
				onClose={handleSave}
				icon={icon}
				addAccount={addAccount}
				search={search}
			/>
		</ListContext.Provider>
	);
};
