import {firestore} from "../firebase/firebase-utils";
import {getFirestoreData} from "../functions/getFirestoreData";

export async function fixAtlasSalesDate() {
	try {
		const atlasSales = await getFirestoreData("atlasSales");
		atlasSales.forEach(async (sale) => {
			if (typeof sale.date === "string") {
				let date = sale.date.split("-");
				date = date[2] + "-" + date[1] + "-" + date[0];
				date = new Date(date);
                console.log(date);
				await firestore.collection("atlasSales").doc(sale.id).update({date: date});
                console.log("Updated to Firestore!")
			}
		});
	} catch (error) {
		console.log(error);
	}
}
