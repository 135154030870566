import {firestore} from "../firebase/firebase-utils";
import {mapSnapshot} from "./mapSnapshot";
import {notification} from "./notification";
import moment from "moment";
import { convertDate } from "./convertDate";

export async function checkSaleDuplication(sale) {
	try {
		console.log(sale);
		const snapshot = await firestore.collection("sales").where("projectFid", "==", sale.projectFid).get();
		if (snapshot.empty) {
			notification("No duplication", "No duplication found", "success");
			return null;
		} else {
			const sales = mapSnapshot(snapshot);
			const found = sales.find((doc) => doc.unitNumber === sale.unitNumber);
			if (found) {
				console.log("Found sale: ");
				console.log(found);
				const {
					saleId,
					buyerName,
					date,
					netPrice,
					projectName,
					remark,
					rens,
					spaPrice,
					status,
					unitNumber,
					unitSize,
				} = found;
				const html = (
					<div>
						<div>Sale Id: {saleId}</div>
						<div>Date: {moment(convertDate(date)).format("DD-MM-YYYY")}</div>
						<div>Buyer: {buyerName}</div>
						{rens.map((ren, i) => (
							<div key={i}>
								<div>{`REN ${i + 1}: ${ren.renName} (${ren.renPercentage}%)`}</div>
							</div>
						))}
						<div>Project: {projectName}</div>
						<div>SPA Price: {spaPrice}</div>
						<div>Net Price: {netPrice}</div>
						<div>Unit Number: {unitNumber}</div>
						<div>Unit Size: {unitSize}</div>
						<div>Remark: {remark}</div>
						<div>Status: {status}</div>
					</div>
				);
				return html;
			} else {
				console.log("No sale found");
				return null;
			}
		}
	} catch (error) {
		console.log(error);
	}
}
