import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import {
	Card as MuiCard,
	CardHeader,
	Chip as MuiChip,
	Paper as MuiPaper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Box,
} from "@material-ui/core";

import {red, green, orange, blue} from "@material-ui/core/colors";

import {spacing} from "@material-ui/system";

import {MoreVertical} from "react-feather";
import moment from "moment";

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) => props.rgbcolor};
  color: ${(props) => props.theme.palette.common.white};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
	overflow-y: auto;
	max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;

// Data
let id = 0;
function createData(number, rens, project, unitNumber) {
	id += 1;
	return {id, number, rens, project, unitNumber};
}
const mappedSales = (sales) => {
	if (sales) {
		let rows = [];
		sales.forEach((sale) => {
			const found = rows.find((row) => row.id === sale.id);
			if (!found) rows.push(sale);
		});
		rows.forEach((row, i) => {
			const founds = sales.filter((sale) => sale.id === row.id);
			const rens = [];
			founds.forEach((found) => {
				rens.push(found.ren);
			});
			rows[i]["rens"] = rens;
		});

		const array = [];
		rows.forEach((row, i) => {
			const data = createData(
				<Chip label={rows.length - i} rgbcolor={green[500]} />,
				row.rens.map((ren, i) => (
					<Typography style={{fontSize: "12px"}} key={i}>
						{ren.renName}
					</Typography>
				)),
				<Box>
					<Typography style={{fontSize: "12px"}}>{row.projectName}</Typography>
					<Typography style={{fontSize: "12px"}}>{row.unitNumber}</Typography>
				</Box>
			);
			array.push(data);
		});
		return array;
	}
};

const DashboardTable = ({sales, monday, sunday}) => {
	const [rows, setRows] = useState();

	useEffect(
		() => {
			const rows = mappedSales(sales);
			setRows(rows);
		},
		[sales]
	);

	return (
		<Card mb={6}>
			<CardHeader
				title={
					<Box display="flex" alignItems="center">
						<Typography variant="h5" style={{marginRight: "5px"}}>
							Latest sales
						</Typography>
						<Chip label={moment(monday).format("ddd DD MMM")} rgbcolor={blue[500]} />
						<Typography style={{marginRight: "5px", marginLeft: "5px"}}>to</Typography>
						<Chip label={moment(sunday).format("ddd DD MMM")} rgbcolor={blue[500]} />
					</Box>
				}
			/>
			<Paper>
				<TableWrapper>
					<Table size={"small"}>
						<TableHead>
							<TableRow>
								<TableCell>No.</TableCell>
								<TableCell>RENs</TableCell>
								<TableCell>Project</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows &&
								rows.map((row, i) => (
									<TableRow key={row.id}>
										<TableCell component="th" scope="row">
											{row.number}
										</TableCell>
										<TableCell style={{minWidth: "180px"}}>{row.rens}</TableCell>
										<TableCell>{row.project}</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableWrapper>
			</Paper>
		</Card>
	);
};

export default DashboardTable;
