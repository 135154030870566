import {Box, Button, Grid, LinearProgress, makeStyles} from "@material-ui/core";
import React, {useRef} from "react";
import AddIcon from "@material-ui/icons/Add";
import {useEffect} from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	control: {
		padding: theme.spacing(1),
		margin: theme.spacing(1),
	},
	input: {
		marginTop: theme.spacing(1),
		margintBottom: theme.spacing(1),
	},
}));

export default function UploadFile({handleClickUpload, progress, accept = "*"}) {
	const classes = useStyles();

	const inputRef = useRef(null);
	const handleChange = (e) => {
		const files = e.target.files;
		handleClickUpload(files);
	};

	const handleClick = (event) => {
		inputRef.current.click();
	};

	return (
		<React.Fragment>
			<input type="file" onChange={handleChange} ref={inputRef} style={{display: "none"}} accept={accept} />
			{progress && (
				<Grid item xs={12}>
					<Box my={1}>
						<LinearProgress variant="determinate" value={progress} />
					</Box>
				</Grid>
			)}
			<Grid item xs={12}>
				<Button
					variant="contained"
					color="primary"
					size="large"
					startIcon={<AddIcon />}
					className={classes.input}
					onClick={handleClick}
				>
					Upload Files
				</Button>
			</Grid>
		</React.Fragment>
	);
}
