export function objectToHtml(data) {
	const notObject = (data) => {
		if (typeof data !== "object") {
			return true;
		} else {
			return false;
		}
	};
	try {
		if (Array.isArray(data)) {
			let count = data.length;
			if (data.length < 5) {
				count = 1;
			} else {
				count = 5;
			}
			let html = [];
			for (let i = 0; i < count; i++) {
				let subHtml = Object.keys(data[i]).map((key, i) => (
					<div key={i} style={{ display: "flex" }}>
						<div style={{ paddingRight: "5px" }}>{key}:</div>
						<div>{notObject(data[i][key]) ? data[i][key] : "Object"}</div>
					</div>
				));
                html.push(<div key={(i+1)*100}><hr/></div>)
                html.push(subHtml)
			}
			return html;
		}
	} catch (err) {
		console.log(err);
	}
}
