import {firestore} from "../firebase/firebase-utils";
import {notification} from "./notification";

export async function createUser(displayName, user, upline) {
	try {
		const snapshot = await firestore.collection("users").where("displayName", "==", displayName).get();
		if (snapshot.empty) {
			const idDoc = await firestore.collection("parameters").doc("parameters").get();
			let userId = null;
			if (idDoc.exists) {
				userId = idDoc.data().userIdCount + 1;
			}
			console.log(userId);
			const response = await firestore.collection("users").add({
				userId: userId,
				displayName: displayName,
				created: new Date(),
				createdBy: user.displayName,
				immediateUpline: upline.displayName,
				immediateUplineFid: upline.id,
			});
			console.log("New user " + displayName + " is created");

			return {id: response.id, displayName: displayName};
		} else {
			notification("Opps", "This user is already in the database", "warning");
			return null;
		}
	} catch (error) {
		console.log(error);
	}
}
