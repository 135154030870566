import * as types from "../../constants";
import {getUserProfile} from "../../functions/getUserProfile";
import {
	signIn as authSignIn,
	signUp as authSignUp,
	resetPassword as authResetPassword,
} from "../../services/authService";
import { signOut as signOutFS } from "../../functions/signOut";

export function signIn(credentials) {
	return async (dispatch) => {
		dispatch({type: types.AUTH_SIGN_IN_REQUEST});

		return authSignIn(credentials)
			.then( async (response) => {
				const authUser = response.user;
				const user = await getUserProfile(authUser);
				dispatch({
					type: types.AUTH_SIGN_IN_SUCCESS,
					user: user,
				});
			})
			.catch((error) => {
				dispatch({type: types.AUTH_SIGN_IN_FAILURE});
				throw error;
			});
	};
}

export function signUp(credentials) {
	return async (dispatch) => {
		dispatch({type: types.AUTH_SIGN_UP_REQUEST});

		return authSignUp(credentials)
			.then((response) => {
				dispatch({
					type: types.AUTH_SIGN_UP_SUCCESS,
					id: response.id,
					email: response.email,
					name: response.name,
				});
			})
			.catch((error) => {
				dispatch({type: types.AUTH_SIGN_UP_FAILURE});
				throw error;
			});
	};
}

export function signOut() {
	return async (dispatch) => {
    await signOutFS()
		dispatch({
			type: types.AUTH_SIGN_OUT,
		});
	};
}

export function resetPassword(credentials) {
	return async (dispatch) => {
		dispatch({type: types.AUTH_RESET_PASSWORD_REQUEST});

		return authResetPassword(credentials)
			.then((response) => {
				dispatch({
					type: types.AUTH_RESET_PASSWORD_SUCCESS,
					email: response.email,
				});
			})
			.catch((error) => {
				dispatch({type: types.AUTH_RESET_PASSWORD_FAILURE});
				throw error;
			});
	};
}
