import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components/macro";

import {
	Grid,
	Divider as MuiDivider,
	Typography as MuiTypography,
	CircularProgress,
	Box,
	withWidth,
} from "@material-ui/core";

import {spacing} from "@material-ui/system";

import {green, red} from "@material-ui/core/colors";

import LineChart from "./LineChart";
import BarChart from "./BarChart";
import Stats from "./Stats";
import Table from "./Table";
import {getSalesByMonth} from "../../functions/getSalesByMonth";
import {convertDate} from "../../functions/convertDate";
import {getTotalSales} from "../../functions/getTotalSales";
import {getMonday} from "../../functions/getMonday";
import {getSunday} from "../../functions/getSunday";
import {getDaysToDate} from "../../functions/getDaysToDate";
import moment from "moment";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function SalesDashboard({width, myFSales, now, screen}) {
	const [salesByMonth, setSalesByMonth] = useState();
	const [totalSales, setTotalSales] = useState("");
	const [totalSalesLastYear, setTotalSalesLastYear] = useState("");
	const [thisWeekSales, setThisWeekSales] = useState();
	const [monday, setMonday] = useState();
	const [sunday, setSunday] = useState();
	const [lastMonday, setLastMonday] = useState();
	const [lastSunday, setLastSunday] = useState();
	const [thisWeekTotalSales, setThisWeekTotalSales] = useState("");
	const [lastWeekTotalSales, setLastWeekTotalSales] = useState("");
	const [weekIncrease, setWeekIncrease] = useState(0);
	const [yearIncrease, setYearIncrease] = useState(0);

	useEffect(
		() => {
			setAllData(
				myFSales,
				setSalesByMonth,
				now,
				setTotalSales,
				setTotalSalesLastYear,
				setThisWeekSales,
				setMonday,
				setSunday,
				setLastMonday,
				setLastSunday,
				setThisWeekTotalSales,
				setLastWeekTotalSales,
				setWeekIncrease,
				setYearIncrease
			);
		},
		[myFSales, now]
	);

	return (
		<React.Fragment>
			<Grid container spacing={screen === "large" ? 6 : 1}>
				<Grid item xs={12} sm={12} md={6} lg={3} xl>
					<Stats
						title="Previous Week"
						amount={lastWeekTotalSales && lastWeekTotalSales.toLocaleString("en-US")}
						chip={moment(lastMonday).format("ddd DD MMM") + " to " + moment(lastSunday).format("ddd DD MMM")}
						percentagecolor={green[500]}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={3} xl>
					<Stats
						title="This Week"
						amount={thisWeekTotalSales && thisWeekTotalSales.toLocaleString("en-US")}
						chip={moment(monday).format("ddd DD MMM") + " to " + moment(sunday).format("ddd DD MMM")}
						percentageText={weekIncrease && weekIncrease.toLocaleString("en-US") + "%"}
						percentagecolor={weekIncrease > 0 ? green[500] : red[500]}
						bottomLabel={"From last week to date"}
					/>
				</Grid>

				<Grid item xs={12} sm={12} md={6} lg={3} xl>
					<Stats
						title="Total Sales"
						amount={totalSalesLastYear && totalSalesLastYear.toLocaleString("en-US")}
						chip="Last Year"
						percentagecolor={red[500]}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={3} xl>
					<Stats
						title="Total Sales"
						amount={totalSales && totalSales.toLocaleString("en-US")}
						chip="Annual"
						percentageText={yearIncrease && yearIncrease.toLocaleString("en-US") + "%"}
						percentagecolor={yearIncrease > 0 ? green[500] : red[500]}
						bottomLabel={"From last year to date"}
					/>
				</Grid>
			</Grid>

			<Grid container spacing={screen === "large" ? 6 : 1}>
				<Grid item xs={12} lg={8}>
					{salesByMonth && <BarChart salesByMonth={salesByMonth} year="2021" />}
				</Grid>
				<Grid item xs={12} lg={4}>
					<Table sales={thisWeekSales} monday={monday} sunday={sunday} />
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

function setAllData(
	myFSales,
	setSalesByMonth,
	now,
	setTotalSales,
	setTotalSalesLastYear,
	setThisWeekSales,
	setMonday,
	setSunday,
	setLastMonday,
	setLastSunday,
	setThisWeekTotalSales,
	setLastWeekTotalSales,
	setWeekIncrease,
	setYearIncrease
) {
	const salesByMonth = getSalesByMonth(myFSales);
	setSalesByMonth(salesByMonth);
	console.log(now);

	const lastYear = new Date(now.getFullYear() - 1, 0, 1).setHours(0, 0, 0, 0);
	const thisYear = new Date(now.getFullYear(), 0, 1).setHours(0, 0, 0, 0);
	const thisYearFSales = myFSales.filter(
		(sale) => convertDate(sale.date) >= thisYear && convertDate(sale.date) <= new Date()
	);
	const totalSales = getTotalSales(thisYearFSales);
	setTotalSales(parseInt(totalSales));

	const lastYearFSales = myFSales.filter(
		(sale) => convertDate(sale.date) >= lastYear && convertDate(sale.date) < thisYear
	);
	const totalSalesLastYear = getTotalSales(lastYearFSales);
	setTotalSalesLastYear(parseInt(totalSalesLastYear));

	const monday = getMonday(0, now);
	const sunday = getSunday(0, now);
	let thisWeekSales = myFSales.filter((sale) => convertDate(sale.date) >= monday && convertDate(sale.date) <= sunday);
	thisWeekSales.sort((a, b) => b.saleId - a.saleId);
	setThisWeekSales(thisWeekSales);
	setMonday(monday);
	setSunday(sunday);

	const thisWeekTotalSales = getTotalSales(thisWeekSales);
	setThisWeekTotalSales(thisWeekTotalSales);

	const lastMonday = getMonday(1, now);
	const lastSunday = getSunday(1, now);
	setLastMonday(lastMonday);
	setLastSunday(lastSunday);
	const lastWeekSales = myFSales.filter(
		(sale) => convertDate(sale.date) > lastMonday && convertDate(sale.date) < lastSunday
	);
	const lastWeekTotalSales = getTotalSales(lastWeekSales);
	setLastWeekTotalSales(lastWeekTotalSales);

	// Week Increase
	let dayFromMonday = now.getDay() - monday.getDay() + 1;
	console.log(now.getDate());
	console.log(new Date().getDate());
	if ((new Date() - now) / 86400400 >= 7) dayFromMonday = 7;

	if (dayFromMonday === 0) dayFromMonday = 7;
	let weekIncrease =
		(thisWeekTotalSales - dayFromMonday / 7 * lastWeekTotalSales) / (dayFromMonday / 7 * lastWeekTotalSales) * 100;
	if (isNaN(weekIncrease)) {
		setWeekIncrease(null);
	} else {
		setWeekIncrease(weekIncrease === -100 ? 0 : weekIncrease.toFixed(1));
	}

	// Year Increase
	const daysToDate = getDaysToDate();
	let yearIncrease =
		(totalSales - totalSalesLastYear * daysToDate / 365) / (totalSalesLastYear * daysToDate / 365) * 100;
	if (isNaN(yearIncrease)) {
		yearIncrease = null;
	} else {
		setYearIncrease(yearIncrease === -100 ? 0 : yearIncrease.toFixed(1));
	}
}

export default withWidth()(SalesDashboard);
