import { firestore } from "../firebase/firebase-utils";

export async function mapAtlasRenAndProject(sale) {
	sale.rens.map(async (ren) => {
		await firestore.collection("users").doc(ren.id).update({atlasName: ren.atlasName});
		console.log("atlasName updated " + ren.atlasName);
	});

	//update project with atlasProjectName;
	await firestore.collection("projects").doc(sale.projectFid).update({atlasProjectName: sale.atlasProjectName});
	console.log("Updated atlasProjectName");
}
