import {
    Button,
    makeStyles,
  } from "@material-ui/core";
  import React, { useRef } from "react";
  import AddIcon from "@material-ui/icons/Add";
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    control: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    input: {
      marginTop: theme.spacing(1),
      margintBottom: theme.spacing(1),
    },
  }));
  
  export default function ChooseFile({ label, handleClickFile }) {
    const classes = useStyles();
  
    const inputRef = useRef(null);
    const handleChange = (e) => {
      const files = e.target.files;
      handleClickFile(files);
    };
  
    const handleClick = (event) => {
      inputRef.current.click();
    };
  
    return (
      <React.Fragment>
        <input
          type="file"
          onChange={handleChange}
          ref={inputRef}
          style={{ display: "none" }}
        />
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            {label}
          </Button>
      </React.Fragment>
    );
  }
  