import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Box, Chip, Grid, Link, Paper, TextField } from "@material-ui/core";
import { AuthContext } from "../context/AuthContext";
import TelegramIcon from "@material-ui/icons/Telegram";
import { sendTelegramMessage } from "../functions/sendTelegramMessage";
import { notification } from "../functions/notification";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chip: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    minHeight: "30px",
  },
}));

function getSteps() {
  return [
    "Register Me in Telegram",
    "Get Telegram Chat Id",
    "Send Verification Code",
  ];
}

export default function RegisterTelegramStepper({ handleClose }) {
  const classes = useStyles();
  const [telegramChatId, setTelegramChatId] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const sendVerificationCode = () => {
    const verificationCode = parseInt(telegramChatId) * 2;
    sendTelegramMessage(
      telegramChatId,
      `Your verification code is: ${verificationCode}`
    );
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setTelegramChatId(value);
  };

  const handleChange2 = (e) => {
    const { value } = e.target;
    setVerificationCode(value);
  };

  const handleSubmit = async () => {
    if (parseInt(verificationCode) === parseInt(telegramChatId) * 2) {
      notification("Yeah", "Subscribed to Telegram", "success");
      handleClose(telegramChatId);
    } else {
      notification(
        "Opps",
        "You have entered the wrong code. Please try again",
        "info"
      );
    }
  };

  const sendMessage = (
    <React.Fragment>
      <Grid item xs={12} align="center">
        <Typography variant="subtitle1">
          Click on the button below to go into the Telegram Bot
        </Typography>
        <Typography variant="subtitle1">
          Type "Register Me" to get chatId
        </Typography>
      </Grid>
      <Grid item xs={12} align="center">
        <Box m={1}>
          <Link color="inherit" href="https://t.me/eliteoneBot" target="blank">
            <Chip
              className={classes.chip}
              label="Message Telegram Bot"
              clickable
              color="primary"
              onDelete={() => {}}
              deleteIcon={<TelegramIcon />}
              variant="outlined"
            />
          </Link>
        </Box>
      </Grid>
    </React.Fragment>
  );

  const enterChatId = (
    <React.Fragment>
      <Grid item xs={12} align="center">
      <Typography variant="subtitle1">
          Enter your Telegram Chat Id below and Click send Verification Code
        </Typography>
        <Box m={1}>
          <TextField
            id="telegram"
            label="Telegram Chat Id"
            variant="outlined"
            value={telegramChatId}
            onChange={handleChange}
          />
        </Box>
      </Grid>
      <Grid item xs={12} align="center">
          
        <Chip
          className={classes.chip}
          label="Send Verification Code"
          clickable
          color="primary"
          variant="outlined"
          onClick={sendVerificationCode}
        />
      </Grid>
    </React.Fragment>
  );

  const enterVerification = (
    <React.Fragment>
      <Grid item xs={12} align="center">
      <Typography variant="subtitle1">
          Enter the verification code sent to your Telegram
        </Typography>
        <Box m={1}>
          <TextField
            id="verificationCode"
            label="Verification Code"
            variant="outlined"
            value={verificationCode}
            onChange={handleChange2}
          />
        </Box>
      </Grid>
      <Grid item xs={12} align="center">
        <Chip
          className={classes.chip}
          label="Submit"
          clickable
          color="primary"
          variant="outlined"
          onClick={handleSubmit}
        />
      </Grid>
    </React.Fragment>
  );

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return sendMessage;
      case 1:
        return enterChatId;
      case 2:
        return enterVerification;
      default:
        return "Unknown stepIndex";
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
        <Paper className={classes.paper}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper></Paper>
      <div>
      <Paper className={classes.paper}>
        {activeStep === steps.length ? (
          <div>
            <Grid container justify="center">
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </Grid>
          </div>
        ) : (
          <div>
            <Grid container justify="center">
              {getStepContent(activeStep)}

              <Grid item>
                <Box m={4}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={activeStep === steps.length - 1}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
        )}
        </Paper>
      </div>
    </div>
  );
}
