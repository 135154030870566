import { mapSnapshot } from "../functions/mapSnapshot";
import { notification } from "../functions/notification";
import { firestore } from "../firebase/firebase-utils";
import exportFromJSON from "export-from-json";
import moment from "moment";

export async function backupCollection(name) {
	try {
		const snapshot = await firestore.collection(name).get();
		if (snapshot.empty) {
			notification("Opps", "No data for" + name + " in database", "warning");
		} else {
			//Export as file
			const data = mapSnapshot(snapshot);
			const date = moment(new Date()).format("YYYY-MM-DD_h_mm_ss_a")
			const fileName = `${name}_${date}_backup`;
			const exportType = "json";
			exportFromJSON({ data, fileName, exportType });
		}
	} catch (error) {
		notification("Opps", error.message, "warning");
	}
}
