import {findUplines} from "./findUplines";

export const getAllUplinesFromSale = async (sale) => {
	const allUplines = await new Promise(async (resolve, reject) => {
		let array = [];
		await Promise.all(
			sale.rens.map(async (ren) => {
				const uplines = await findUplines(ren);
				array = array.concat(uplines);
				return uplines;
			})
		);
		resolve(array);
	});

	// Remove duplicates
	const array = [];
	allUplines.forEach((user) => {
		const found = array.find((doc) => doc.id === user.id);
		if (!found) array.push(user);
	});

	console.log("All uplines");
	console.log(array.map((user) => user.displayName));
	return array;
};
