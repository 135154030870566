import {firestore} from "../firebase/firebase-utils";
import {mapSnapshot} from "./mapSnapshot";

export async function loadDownlines(user) {
	try {
		if (!user) {
			return [];
		}
		let downlinesArray = [];
		async function getDownlines(user) {
			const snapshot = await firestore.collection("users").where("immediateUplineFid", "==", user.id).get();
			if (snapshot.empty) {
				return null;
			}
			const downlines = mapSnapshot(snapshot);

			if (downlines) {
				const nextDownlines = await Promise.all(
					downlines.map(async (downline) => {
						await getDownlines(downline);
						return downline;
					})
				);
				downlinesArray = downlinesArray.concat(nextDownlines);
				return downlinesArray;
			}
		}
		// Put ownself as first downline
		downlinesArray = [user];
		const downlines = await getDownlines(user);

		if (downlines) {
			return downlines;
		} else {
			return [user];
		}
	} catch (error) {
		console.log(error);
	}
}
