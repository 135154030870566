import {getFirestoreData} from "./getFirestoreData";

export async function getUser(id) {
	try {
		const user = getFirestoreData("users", id);
		return user;
	} catch (error) {
		console.log(error);
	}
}
