import firebase from "firebase";

export async function sendTelegramMessage(chatId, message) {
  const collectionRef = firebase.firestore().collection("telegram");
  try {
    const writeResult = await collectionRef.add({
      chatId: chatId,
      message: message,
    });
    console.log(writeResult.id + " added into Firebase Telegram");
  } catch (err) {
    console.log(err);
  }
}
