import {Box, Button, CircularProgress, Divider, Grid, Typography, withWidth} from "@material-ui/core";
import React from "react";
import {useContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import Actions from "../components/SalesDashboard/Actions";
import SalesDashboard from "../components/SalesDashboard/SalesDashboard";
import {AuthContext} from "../context/AuthContext";
import {ListContext} from "../context/ListContext";
import {SalesContext} from "../context/SalesContext";
import {flattenSales} from "../functions/flattenSales";
import {getDownlines} from "../functions/getDownlines";

function PersonalSalesPage({width}) {
	const {user} = useContext(AuthContext);
	const {sales, loading, fSales, downlines} = useContext(SalesContext);
	const [member, setMember] = useState(user);
	const [myFSales, setMyFSales] = useState([]);
	const [screen, setScreen] = useState("large");
	const {openGlobalList} = useContext(ListContext);
	const [now, setNow] = useState(new Date());

	useEffect(
		() => {
			if (width === "sm" || width === "xs") {
				setScreen("small");
			} else {
				setScreen("large");
			}
		},
		[width]
	);

	useEffect(
		() => {
			if (fSales) {
				let myFSales = flattenSales(sales, [member]);
				myFSales = myFSales.filter((sale) => sale.status !== "Cancelled");
				setMyFSales(myFSales);
			}
		},
		[sales, fSales, member]
	);

	const handleClickMember = async () => {
		const object = await openGlobalList(
			downlines,
			"Select a user",
			"displayName",
			"face",
			false, //Add account is false
			true // Search is true
		);
		if (object) {
			setMember(object);
		}
	};

	return (
		<React.Fragment>
			{loading ? (
				<div>{Loading(loading)}</div>
			) : (
				<React.Fragment>
					<Helmet title="Default Dashboard" />
					<Grid justify="space-between" container spacing={screen === "large" ? 6 : 1}>
						<Grid item>
							<Typography variant="h3" gutterBottom>
								Individual Sales
							</Typography>
							<Typography variant="subtitle1">
								Welcome back, {user.displayName}! {" "}
								<span role="img" aria-label="Waving Hand Sign">
									👋
								</span>
							</Typography>
							<Typography variant="h5" style={{marginTop: "15px"}}>
								Individual Sales for {member.displayName}
							</Typography>
						</Grid>

						<Grid item>
							<Box display="flex" alignItems="center">
								<Button style={{marginRight: "10px"}} variant="outlined" onClick={handleClickMember}>
									Choose member
								</Button>
								<Actions now={now} setNow={setNow} />
							</Box>
						</Grid>
					</Grid>
					<Box my={3}>
						<Divider my={6} />
					</Box>

					<SalesDashboard myFSales={myFSales} now={now} screen={screen} />
					
				</React.Fragment>
			)}
		</React.Fragment>
	);
}

export default withWidth()(PersonalSalesPage);

function Loading(loading) {
	return (
		<Box display="flex" alignItems="center">
			<CircularProgress />
			<Typography variant="body2" style={{margin: "15px"}}>
				{loading}
			</Typography>
		</Box>
	);
}
