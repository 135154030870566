import {firestore} from "../firebase/firebase-utils";
import {notification} from "./notification";

export async function deleteUser(user) {
	try {
		await firestore.collection("users").doc(user.id).delete();
		notification("Deleted", "User is deleted", "success");
	} catch (error) {
		console.log(error);
	}
}
