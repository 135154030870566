import React, {useEffect, useState} from "react";
import {numberWithCommas} from "../functions/number-formating";
import {Grid, makeStyles, Typography, withWidth} from "@material-ui/core";
import {blue} from "@material-ui/core/colors";

function SalesTotalChart(props) {
	let {chartFieldToDisplay, sale, n, barChartLengths, itemWidth, width} = props;
	const [screen, setScreen] = useState("small");

	useEffect(
		() => {
			if (width === "sm" || width === "xs") {
				setScreen("small");
			} else {
				setScreen("large");
			}
			// 'lg', 'md', 'sm', 'xl', 'xs'
		},
		[width]
	);

	const useStyles = makeStyles((theme) => ({
		font: {
			fontSize: screen === "small" ? "12px" : "16px",
		},
	}));

	const classes = useStyles();

	return (
		<React.Fragment>
			{!n && (
				<Grid container wrap="nowrap">
					{Object.keys(chartFieldToDisplay).map((key, i) => {
						return (
							<Grid item key={i} style={{width: itemWidth[key]}} zeroMinWidth>
								{(() => {
									switch (key) {
										case "barChart":
											return <Typography className={classes.font} />;
										case "totalSales":
											return (
												<Typography
													align="right"
													style={{fontWeight: "bold"}}
													variant="body2"
													className={classes.font}
												>
													{chartFieldToDisplay[key]}
												</Typography>
											);
										default:
											return (
												<Typography
													noWrap
													style={{fontWeight: "bold"}}
													variant="body2"
													className={classes.font}
												>
													{chartFieldToDisplay[key]}
												</Typography>
											);
									}
								})()}
							</Grid>
						);
					})}
				</Grid>
			)}
			<Grid container wrap="nowrap">
				{Object.keys(chartFieldToDisplay).map((key, i) => (
					<Grid item style={{width: itemWidth[key]}} zeroMinWidth key={i}>
						{(() => {
							switch (key) {
								case "barChart":
									return (
										<Typography
											noWrap
											className={classes.font}
											style={{
												color: blue[800],
												paddingLeft: "10px",
												paddingTop: "3px",
												fontSize: "8px",
											}}
										>
											{Array(barChartLengths[n]).fill().map((i) => {
												return <React.Fragment key={i}>&#9608;</React.Fragment>;
											})}
										</Typography>
									);
								case "totalSales":
									return (
										<Typography
											align="right"
											style={{fontFamily: "Roboto"}}
											variant="body2"
											className={classes.font}
										>
											{numberWithCommas(sale[key])}
										</Typography>
									);
								default:
									return (
										<Typography
											className={classes.font}
											noWrap
											variant="body2"
											style={{color: "#2e7c95", fontWeight: "bold", fontFamily: "Roboto"}}
										>
											{sale[key]}
										</Typography>
									);
							}
						})()}
					</Grid>
				))}
			</Grid>
		</React.Fragment>
	);
}

export default withWidth()(SalesTotalChart);
