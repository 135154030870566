import React, {useContext} from "react";
import styled from "styled-components/macro";
import {NavLink} from "react-router-dom";

import {Box, Container, Grid, IconButton, Tooltip, Typography as MuiTypography} from "@material-ui/core";

import {spacing} from "@material-ui/system";
import {AuthContext} from "../../../context/AuthContext";

const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled.div`
	padding-top: 3.5rem;
	position: relative;
	text-align: center;
	overflow: hidden;
`;

const Content = styled.div`
	padding: ${(props) => props.theme.spacing(6)}px 0;
	line-height: 150%;
`;

const Image = styled.img`
	max-width: 100%;
	height: auto;
	display: block;
	box-shadow: 0 6px 18px 0 rgba(18, 38, 63, 0.075);
	border-radius: 5px;
	transform: perspective(2000px) rotateX(25deg);
	z-index: 0;
	position: relative;
	image-rendering: optimizequality;
	image-rendering: -webkit-optimize-contrast;
	margin-bottom: -100px;
	margin-top: -35px;
	${(props) => props.theme.breakpoints.up("md")} {
		margin-top: -50px;
	}
`;

const ImageWrapper = styled.div`
	&:before {
		background: linear-gradient(transparent, rgba(0, 0, 0, 0.02));
		bottom: 0;
		left: 0;
		position: absolute;
		content: " ";
		z-index: 1;
		display: block;
		width: 100%;
		height: 75px;
		pointer-events: none;
	}
`;

const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 2rem;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.5rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)}px 0;
`;

const BrandIcons = styled.div(spacing);

const BrandIcon = styled.img`
	vertical-align: middle;
	margin-right: ${(props) => props.theme.spacing(3)}px;
	height: auto;
`;

const Feature = styled.div`
	display: inline-block;
	padding: 0 ${(props) => props.theme.spacing(3)}px;
	text-align: left;
`;

const FeatureText = styled(Typography)`
  color: ${(props) => props.theme.palette.secondary.main};
`;

function Introduction() {
	const {user} = useContext(AuthContext);

	const isAdmin = () => {
		let display = false;
		if (user.role) {
			if (user.role === "Super Admin") display = true;
			if (user.role === "Admin") display = true;
		}
		return display;
	};

	return (
		<Wrapper>
			<Container>
				<Grid container alignItems="center" justify="center" spacing={4}>
					<Grid item xs={12} sm={9} md={8} lg={7}>
						<Content>
							<Title variant="h1" gutterBottom>
								DJC Sales System V2
							</Title>
							<Grid container justify="center" spacing={4}>
								<Grid item xs={12} lg={10}>
									<NavLink to="/dashboard/team">
										<IconButton>
											<Typography variant="h5" color="textSecondary">
												Dashboard
											</Typography>
										</IconButton>
									</NavLink>
								</Grid>
								{isAdmin() && (
									<Grid item xs={12} lg={10}>
										<NavLink to="/pages/atlasMapping">
											<IconButton>
												<Typography variant="h5" color="textSecondary">
													Atlas Mapping
												</Typography>
											</IconButton>
										</NavLink>
									</Grid>
								)}
							</Grid>
						</Content>
					</Grid>
				</Grid>
			</Container>
		</Wrapper>
	);
}

export default Introduction;
