import React from "react";
import styled, {withTheme} from "styled-components/macro";
import {Card as MuiCard, CardContent, CardHeader, IconButton} from "@material-ui/core";

import {spacing} from "@material-ui/system";

import {fade} from "@material-ui/core/styles/colorManipulator";

import {Line} from "react-chartjs-2";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`height: 378px;`;

function LineChart({theme, salesByMonth, year}) {
	const data = (canvas) => {
		const ctx = canvas.getContext("2d");

		const gradient = ctx.createLinearGradient(0, 0, 0, 300);
		gradient.addColorStop(0, fade(theme.palette.secondary.main, 0.0875));
		gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

		const datas = [];
		for (let i = 1; i <= 12; i++) {
			let key = "";
			if (i < 10) {
				key = `${year}0${i}`;
			} else {
				key = `${year}${i}`;
			}
			if (salesByMonth[key]) {
				datas.push(parseInt(salesByMonth[key]));
			} else {
				datas.push(0);
			}
		}

		return {
			labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
			datasets: [
				{
					label: "Sales (RM)",
					fill: true,
					backgroundColor: gradient,
					borderColor: theme.palette.secondary.main,
					data: datas,
				},
			],
		};
	};

	const options = {
		maintainAspectRatio: false,
		legend: {
			display: false,
		},
		tooltips: {
			callbacks: {
				label: function(tooltipItem, data) {
					var value = data.datasets[0].data[tooltipItem.index];
					if (parseInt(value) >= 1000) {
						return "RM" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					} else {
						return "RM" + value;
					}
				},
			},
			intersect: false,
		},
		hover: {
			intersect: true,
		},
		plugins: {
			filler: {
				propagate: false,
			},
		},
		scales: {
			xAxes: [
				{
					reverse: true,
					gridLines: {
						color: "rgba(0,0,0,0.0)",
					},
					ticks: {
						fontColor: theme.palette.text.secondary,
					},
				},
			],
			yAxes: [
				{
					ticks: {
						userCallback: function(value) {
							return Number(value / 1000000).toLocaleString("en") + " mil";
						},
						fontColor: theme.palette.text.secondary,
					},
					display: true,
					borderDash: [5, 5],
					gridLines: {
						color: "rgba(0,0,0,0.0375)",
						fontColor: "#fff",
					},
				},
			],
		},
		plugins: {
			datalabels: {
				formatter: function(value, context) {
					return context.chart.data.labels[context.dataIndex];
				},
				align: "top",
				anchor: "center",
				offset: 25,
				padding: -2,
				clip: true,
				font: {
					size: "16",
					weight: "bold",
				},
			},
		},
	};

	return (
		<Card mb={3}>
			<CardHeader title="Total sales" />
			<CardContent style={{paddingLeft: "2px", paddingRight: "2px"}}>
				<ChartWrapper>
					<Line data={data} options={options} />
				</ChartWrapper>
			</CardContent>
		</Card>
	);
}
export default withTheme(LineChart);
