import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import AddIcon from "@material-ui/icons/Add";
import {arrayIsEmpty} from "../functions/arrayIsEmpty";
import {blue} from "@material-ui/core/colors";
import {Icon, Grid, TextField, Button, Typography} from "@material-ui/core";
import {Box} from "@material-ui/core";
import {IconButton} from "@material-ui/core";

const useStyles = makeStyles({
	avatar: {
		backgroundColor: blue[100],
		color: blue[600],
	},
});

export default function GlobalList(props) {
	const classes = useStyles();
	const {onClose, selectedValue, open, objects=[], displayName, title, icon, addAccount, search} = props;

	const [searchValue, setSearchValue] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(50);

	const handleClose = () => {
		onClose();
    setSearchValue("")
	};

	const handleListItemClick = (value) => {
		onClose(value);
    setSearchValue("")
	};

	let filteredObjects = objects.filter((object) => {
		if (object[displayName]) {
			if (object[displayName].toLowerCase().includes(searchValue.toLowerCase())) {
				return true;
			} else {
				return false;
			}
		}
	});

	filteredObjects = filteredObjects.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
	const totalPages = Math.floor(objects.length / rowsPerPage) + 1;

	return (
		<Dialog onClose={handleClose} open={open}>
			<DialogTitle id="simple-dialog-title" style={{padding: "10px"}}>
				<Grid container>
					<Grid item xs={12}>
						<Box display="flex" alignItems="center">
							<IconButton size="small" onClick={handleClose}>
								<Icon>clear</Icon>
							</IconButton>
							{title}
						</Box>
					</Grid>
					{search && (
						<Grid item xs={12}>
							<Box m={1}>
								<TextField
									variant="outlined"
									label="search"
									fullWidth
									value={searchValue}
									onChange={(e) => {
										setPage(0);
										setSearchValue(e.target.value);
									}}
								/>
							</Box>
						</Grid>
					)}
				</Grid>
			</DialogTitle>
			<Grid
				container
				style={{
					overflowX: "hidden",
					overflowY: "auto",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<List style={{minWidth: "350px", height: "600px"}}>
					{!arrayIsEmpty(objects) &&
						filteredObjects.map((object, i) => (
							<ListItem button onClick={() => handleListItemClick(object)} key={i}>
								<ListItemAvatar>
									<Avatar className={classes.avatar}>
										<Icon>{icon}</Icon>
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary={object[displayName]} />
							</ListItem>
						))}
					{addAccount && (
						<ListItem button onClick={() => handleListItemClick("addAccount")}>
							<ListItemAvatar>
								<Avatar>
									<AddIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="Add account" />
						</ListItem>
					)}
				</List>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<Box display="flex" alignItems="center" m={2}>
						<Button
							variant="outlined"
							disabled={page === 0}
							onClick={() => {
								setPage(page - 1);
							}}
						>
							Last Page
						</Button>
						<Typography variant="body2" color="textSecondary" style={{margin: "15px"}}>
							Page {page + 1} / {totalPages}
						</Typography>
						<Button
							variant="outlined"
							disabled={page + 1 === totalPages}
							onClick={() => {
								setPage(page + 1);
							}}
						>
							Next Page
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Dialog>
	);
}
