import {firestore} from "../firebase/firebase-utils";
import {notification} from "./notification";

export async function saveUser(user) {
	try {
		await firestore.collection("users").doc(user.id).update(user);
		notification("Success", "User is updated successfully", "success");
	} catch (error) {
		console.log(error);
		notification("Opps", "Something went wrong", "danger");
	}
}
