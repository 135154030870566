const {firestore} = require("../firebase/firebase-utils");
const {getFirestoreData} = require("./getFirestoreData");

export const findUplines = async (user) => {
	const first = await getFirestoreData("users", user.userFid);

	let uplinesArray = [first];
	const getUpline = async (user) => {
		if (!user.immediateUplineFid) {
			return uplinesArray;
		}
		const doc = await firestore.collection("users").doc(user.immediateUplineFid).get();
		if (!doc.exists) {
			return uplinesArray;
		}
		const upline = {...doc.data(), id: doc.id};
		uplinesArray = uplinesArray.concat(upline);
		await getUpline(upline);

		return uplinesArray;
	};

	const uplines = await getUpline(first);

	return uplines;
};
