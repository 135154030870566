export function convertUndefined(object) {
	let obj = JSON.parse(
		JSON.stringify(object, function(k, v) {
			if (v === undefined) {
				return null;
			}
			return v;
		})
	);
	return obj;
}
