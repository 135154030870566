import {
	CardContent,
	Grid,
	Link,
	Typography,
	Breadcrumbs as MuiBreadcrumbs,
	Card as MuiCard,
	Divider as MuiDivider,
	Box,
	Button as MuiButton,
	CircularProgress,
} from "@material-ui/core";
import {spacing} from "@material-ui/system";
import React, {useContext, useState} from "react";
import {Helmet} from "react-helmet-async";
import {NavLink} from "react-router-dom";
import styled from "styled-components/macro";
import ChooseFile from "../components/ChooseFile";
import FullScreenDialog from "../components/FullDialog";
import {ConfirmationContext} from "../context/ConfirmationContext";
import {notification} from "../functions/notification";
import {objectToHtml} from "../functions/objectToHtml";
import {backupCollection} from "./backupCollection";
import { findUplines } from "./findUplines";
import {fixAtlasSalesDate} from "./fixAtlasSalesDate";
import {importCollection} from "./importCollection";
import {populateAtlasNames} from "./populateAtlasNames";
import {populateDownlines} from "./populateDownlines";
import { populateTeamIds } from "./populateTeamIds";
import UploadExcel from "./UploadExcel";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Button = styled(MuiButton)(spacing);

function TestList({setOpen}) {
	const [loading, setLoading] = useState(false);
	const [filename, setFilename] = useState();
	const [data, setData] = useState();
	const {openConfirmation} = useContext(ConfirmationContext);

	const handleClickBackup = async (data) => {
		setLoading(true);
		await backupCollection(data);
		setLoading(false);
	};

	const handleClickImport = async (collection) => {
		setLoading(true);
		const message = data && objectToHtml(data);
		const response = await openConfirmation("Confirm upload to " + collection + "?", message);
		if (response) {
			if (filename && filename.includes(collection)) {
				const response = await importCollection(data, collection);
				console.log(response);
				setLoading(false);
			} else {
				notification("Opps", filename + " does not belong to " + collection, "danger");
				setLoading(false);
			}
		}
	};

	const handleClickFile = (files) => {
		console.log(files);
		const reader = new FileReader();
		reader.addEventListener("load", (event) => {
			const response = event.target.result;
			console.log(JSON.parse(response));
			setData(JSON.parse(response));
			setFilename(files[0].name);
		});

		reader.readAsText(files[0]);
	};

	return (
		<Card mb={6}>
			<CardContent>
				{filename && filename}
				<Box my={2} display="flex" alignItems="center">
					<Button
						size="small"
						variant="contained"
						color="primary"
						onClick={() => handleClickBackup("users")}
						mr={2}
					>
						Back up users
					</Button>
					<ChooseFile label="Upload JSON" handleClickFile={handleClickFile} />
					<Button
						size="small"
						variant="contained"
						color="primary"
						onClick={() => handleClickImport("users")}
						mx={2}
					>
						Import users
					</Button>
				</Box>
				<Box my={2} display="flex" alignItems="center">
					<Button
						size="small"
						variant="contained"
						color="primary"
						onClick={() => handleClickBackup("sales")}
						mr={2}
					>
						Back up sales
					</Button>
					<ChooseFile label="Upload JSON" handleClickFile={handleClickFile} />
					<Button
						size="small"
						variant="contained"
						color="primary"
						onClick={() => handleClickImport("sales")}
						mx={2}
					>
						Import sales
					</Button>
				</Box>
				<Box my={2} display="flex" alignItems="center">
					<Button
						size="small"
						variant="contained"
						color="primary"
						onClick={() => handleClickBackup("projects")}
						mr={2}
					>
						Back up projects
					</Button>
					<ChooseFile label="Upload JSON" handleClickFile={handleClickFile} />
					<Button
						size="small"
						variant="contained"
						color="primary"
						onClick={() => handleClickImport("projects")}
						mx={2}
					>
						Import projects
					</Button>
				</Box>
				<Box my={2} display="flex" alignItems="center">
					<Button
						size="small"
						variant="contained"
						color="primary"
						onClick={() => handleClickBackup("atlasSales")}
						mr={2}
					>
						Back up Atlas Sales
					</Button>
					<ChooseFile label="Upload JSON" handleClickFile={handleClickFile} />
					<Button
						size="small"
						variant="contained"
						color="primary"
						onClick={() => handleClickImport("atlasSales")}
						mx={2}
					>
						Import Atlas Sales
					</Button>
				</Box>
				<Box my={2} display="flex" alignItems="center">
					<Button size="small" variant="contained" color="primary" onClick={populateDownlines} mr={2}>
						Populate downlines
					</Button>
				</Box>
				<Box my={2} display="flex" alignItems="center">
					<Button size="small" variant="contained" color="primary" onClick={fixAtlasSalesDate} mr={2}>
						Fix Atlas Sale Date format
					</Button>
				</Box>
				<Box my={2} display="flex" alignItems="center">
					<Button size="small" variant="contained" color="primary" onClick={()=>setOpen(true)} mr={2}>
						Populate Atlas Names
					</Button>
				</Box>
				<Box my={2} display="flex" alignItems="center">
					<Button size="small" variant="contained" color="primary" onClick={()=>findUplines({displayName: "ABC", userFid:"9kUH9ovtQgBlMDkFfG43", immediateUplineFid: "QetHPVwMdl9hU1UFNldM"})} mr={2}>
						Get Uplines
					</Button>
				</Box>
				<Box my={2} display="flex" alignItems="center">
					<Button size="small" variant="contained" color="primary" onClick={populateTeamIds} mr={2}>
						Populate teamIds in sales
					</Button>
				</Box>
			</CardContent>
			{loading && <CircularProgress />}
		</Card>
	);
}

export default function Test() {
	
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Helmet title="Atlas Mapping" />
			<Typography variant="h3" gutterBottom display="inline">
				Testing Page
			</Typography>

			<Breadcrumbs aria-label="Breadcrumb" mt={2}>
				<Link component={NavLink} exact to="/">
					Dashboard
				</Link>
				<Link component={NavLink} exact to="/">
					Pages
				</Link>
				<Typography>Testing Page</Typography>
			</Breadcrumbs>

			<Divider my={6} />

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<TestList setOpen={setOpen}  />
				</Grid>
			</Grid>
			<FullScreenDialog
				open={open}
				setOpen={setOpen}
				handleClose={handleClose}
				title="Upload Excel"
				component={<UploadExcel handleClose={handleClose} />}
			/>
		</React.Fragment>
	);
}
