import {firestore} from "../firebase/firebase-utils";
import {getFirestoreData} from "../functions/getFirestoreData";

export async function populateAtlasNames(excelUsers) {
	try {
		const users = await getFirestoreData("users");
		let i = 1;
		excelUsers.forEach(async (eUser) => {
			const snapshot = await firestore.collection("users").where("email", "==", eUser.email).get();
			if (snapshot.empty) {
				return;
			} else {
				const foundUser = {...snapshot.docs[0].data(), id: snapshot.docs[0].id};
				i = i + 1;
				await firestore.collection("users").doc(foundUser.id).update({atlasName: eUser.name});
				console.log(i + ") " + eUser.name + " updated as atlasName");
			}
		});
	} catch (error) {
		console.log(error);
	}
}
