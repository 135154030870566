import {firestore} from "../firebase/firebase-utils";
import {notification} from "./notification";

export async function deleteSale(sale) {
	try {
		await firestore.collection("sales").doc(sale.id).delete();
		notification("Deleted", "The sale has been deleted", "success");
	} catch (error) {
		console.log(error);
	}
}
