import moment from 'moment';
import { notification } from './notification';

export function toHTML(object) {
	const {
		date,
		buyerName,
		rens,
		projectName,
		projectFid,
		spaPrice,
		netPrice,
		unitNumber,
		unitSize,
		remark,
		status,
		saleId,
		atlasProjectName
	} = object;
	const package_ = object.package;

	if(!rens){
		notification('Opps', 'Please map rens', 'warning');
		return;
	}
	let mapped = 0;
	rens.forEach((ren) => {
		if (ren.userFid) {
			mapped++;
		}
	});
	if (mapped !== rens.length) {
		notification('Opps', 'Please map rens', 'warning');
		return;
	}
	if (!projectFid) {
		notification('Opps', 'Please map project', 'warning');
		return;
	}

	const html = (
		<div>
			<div>Sale Id: {saleId}</div>
			<div>Date: {moment(date).format('DD-MM-YYYY')}</div>
			<div>Buyer: {buyerName}</div>
			{rens.map((ren, i) => (
				<div key={i}>
					<div>{`REN ${i + 1}: ${ren.renName} (${ren.renPercentage}%)`}</div>
					<div>{`REN ${i + 1} Fid: ${ren.userFid}`}</div>
				</div>
			))}
			<div>Project: {projectName}</div>
			<div>Atlas Project Name: {atlasProjectName}</div>
			<div>Project Fid: {projectFid}</div>
			<div>SPA Price: {spaPrice}</div>
			<div>Net Price: {netPrice}</div>
			<div>Unit Number: {unitNumber}</div>
			<div>Unit Size: {unitSize}</div>
			<div>Package: {package_}</div>
			<div>Remark: {remark}</div>
			<div>Status: {status}</div>
		</div>
	);
	return html;
}
