import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components/macro";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import moment from "moment";

import {
	CardContent,
	Grid,
	Link,
	Breadcrumbs as MuiBreadcrumbs,
	Card as MuiCard,
	Divider as MuiDivider,
	Typography,
	Box,
	Button,
	CardActions,
	LinearProgress,
	Tabs,
	Tab,
	Badge,
	Fade,
	Grow,
} from "@material-ui/core";

import {spacing} from "@material-ui/system";
import {loadUsers} from "../../functions/loadUsers";
import {loadAtlasSales} from "../../functions/loadAtlasSales";
import {ListContext} from "../../context/ListContext";
import {arrayIsEmpty} from "../../functions/arrayIsEmpty";
import {loadProjects} from "../../functions/loadProjects";
import {convertNumber} from "../../functions/convertNumber";
import {getSaleId} from "../../functions/getSaleId";
import {ConfirmationContext} from "../../context/ConfirmationContext";
import {toHTML} from "../../functions/toHTML";
import {notification} from "../../functions/notification";
import {saveSaleFromAtlas} from "../../functions/saveSaleFromAtlas";
import {getRenFromAtlasName} from "../../functions/getRenFromAtlasName";
import {getProjectFromAtlasProjectName} from "../../functions/getProjectFromAtlasProjectName";
import {checkSaleDuplication} from "../../functions/checkSaleDuplication";
import {mapAtlasRenAndProject} from "../../functions/mapAtlasNameAndProject";
import {InputContext} from "../../context/InputContext";
import {createUser} from "../../functions/createUser";
import {AuthContext} from "../../context/AuthContext";
import {convertDate} from "../../functions/convertDate";
import {changeAtlasSaleMapped} from "../../functions/changeAtlasSaleMapped";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function RenCard({rens, users, setRens}) {
	const {openGlobalList} = useContext(ListContext);
	const [mappedRens, setMappedRens] = useState();
	const {openDialog} = useContext(InputContext);
	const {user} = useContext(AuthContext);

	useEffect(
		() => {
			const fetchData = async () => {
				let mappedRens = await new Promise(async (resolve, reject) => {
					if (rens) {
						const mappedRens = [];
						await Promise.all(
							rens.map(async (ren) => {
								const appRen = await getRenFromAtlasName(ren);
								mappedRens.push({
									atlasName: ren.name,
									renName: appRen.renName,
									userFid: appRen.renFid,
									renPercentage: parseFloat(ren.percentage),
								});
							})
						);
						resolve(mappedRens);
					}
				});
				setMappedRens(mappedRens);
				let mapped = 0;
				mappedRens.forEach((ren) => {
					if (ren.userFid) {
						mapped++;
					}
				});
				if (mapped === mappedRens.length) {
					setRens(mappedRens);
				}
			};

			fetchData();
		},
		[rens]
	);

	const handleClickMapRen = async (i) => {
		let userSelected = await openGlobalList(users, "Select a ren", "displayName", "account_circle", true, true);
		if (!userSelected) {
			return;
		}
		if (userSelected === "addAccount") {
			const userName = await openDialog("Add user", "Please enter the user fullname", "username", "");
			const upline = await openGlobalList(users, "Select upline", "displayName", "face", false, true);
			if (!userName) return;
			if(!upline) return;
			const newUser = await createUser(userName, user, upline);
			if (newUser) {
				userSelected = newUser;
			} else {
				return;
			}
		}
		console.log(userSelected);

		const newMappedRens = [...mappedRens];
		newMappedRens[i].renName = userSelected.displayName;
		newMappedRens[i].id = userSelected.id;
		newMappedRens[i].userFid = userSelected.id;
		setMappedRens(newMappedRens);
		setRens(newMappedRens);
	};
	return (
		<React.Fragment>
			{!arrayIsEmpty(rens) &&
				mappedRens &&
				mappedRens.map((ren, i) => (
					<Grid container spacing={2} alignItems="center" key={i}>
						<Grid item xs={5}>
							<Box display="flex" alignItems="center">
								<Typography variant="body2" gutterBottom style={{paddingRight: "5px"}}>
									{ren.atlasName}
								</Typography>
								<Typography variant="body2" gutterBottom>
									{`(${ren.renPercentage}%)`}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={5}>
							<Typography variant="body2" gutterBottom>
								{!arrayIsEmpty(mappedRens) && mappedRens[i].renName}
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Button
								variant="contained"
								size="small"
								color="primary"
								onClick={() => handleClickMapRen(i)}
							>
								Map REN
							</Button>
						</Grid>
					</Grid>
				))}
		</React.Fragment>
	);
}

function ProjectCard({project, projects, setProject}) {
	const [mappedProject, setMappedProject] = useState();
	const {openGlobalList} = useContext(ListContext);

	useEffect(
		() => {
			const fetchData = async () => {
				const myProject = await getProjectFromAtlasProjectName(project);
				setMappedProject(myProject);
				if (myProject.id) {
					setProject({...myProject, atlasProjectName: project});
				}
			};

			fetchData();
		},
		[projects]
	);

	const handleClickMapProject = async () => {
		const projectSelected = await openGlobalList(
			projects,
			"Select a project",
			"projectName",
			"account_circle",
			false,
			true
		);
		console.log(projectSelected);
		setMappedProject(projectSelected);
		setProject({...projectSelected, atlasProjectName: project});
	};

	return (
		<React.Fragment>
			<Grid container spacing={2} alignItems="center">
				<Grid item xs={5}>
					<Typography variant="body2" gutterBottom>
						{project}
					</Typography>
				</Grid>
				<Grid item xs={5}>
					<Typography variant="body2" gutterBottom>
						{mappedProject && mappedProject.projectName}
					</Typography>
				</Grid>
				<Grid item xs={2}>
					<Button variant="contained" size="small" color="primary" onClick={handleClickMapProject}>
						Map Project
					</Button>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

function Others({sale}) {
	const handleClickMapped = async (sale) => {
		await changeAtlasSaleMapped(sale);
	};

	return (
		<React.Fragment>
			<Grid container spacing={2} alignItems="center">
				<Grid item xs={5}>
					<Typography variant="body2" gutterBottom>
						Date:
					</Typography>
				</Grid>
				<Grid item xs={7}>
					<Typography variant="body2" gutterBottom>
						{moment(convertDate(sale.date)).format("YYYY-MM-DD")}
					</Typography>
				</Grid>
				<Grid item xs={5}>
					<Typography variant="body2" gutterBottom>
						Unit Number:
					</Typography>
				</Grid>
				<Grid item xs={7}>
					<Typography variant="body2" gutterBottom>
						{sale.unitNumber}
					</Typography>
				</Grid>
				<Grid item xs={5}>
					<Typography variant="body2" gutterBottom>
						SPA Price:
					</Typography>
				</Grid>
				<Grid item xs={7}>
					<Typography variant="body2" gutterBottom>
						{sale.spaPrice}
					</Typography>
				</Grid>
				<Grid item xs={5}>
					<Typography variant="body2" gutterBottom>
						Net Price:
					</Typography>
				</Grid>
				<Grid item xs={7}>
					<Typography variant="body2" gutterBottom>
						{sale.netPrice}
					</Typography>
				</Grid>
				<Grid item xs={5}>
					<Typography variant="body2" gutterBottom>
						Package:
					</Typography>
				</Grid>
				<Grid item xs={7}>
					<Typography variant="body2" gutterBottom>
						{sale.package}
					</Typography>
				</Grid>
				<Grid item xs={5}>
					<Typography variant="body2" gutterBottom>
						Remark:
					</Typography>
				</Grid>
				<Grid item xs={7}>
					<Typography variant="body2" gutterBottom>
						{sale.remark}
					</Typography>
				</Grid>
				<Grid item xs={5}>
					<Typography
						variant="body2"
						gutterBottom
						style={{cursor: "pointer"}}
						onClick={() => handleClickMapped(sale)}
					>
						Mapped:
					</Typography>
				</Grid>
				<Grid item xs={7}>
					<Typography variant="body2" gutterBottom>
						{sale.mapped.toString()}
					</Typography>
				</Grid>
				{sale.leaders &&
					sale.leaders.map((leader, i) => (
						<React.Fragment key={i}>
							<Grid item xs={5}>
								<Typography variant="body2" gutterBottom>
									REN {i + 1} Leader:
								</Typography>
							</Grid>
							<Grid item xs={7}>
								<Typography variant="body2" gutterBottom>
									{leader}
								</Typography>
							</Grid>
						</React.Fragment>
					))}
			</Grid>
		</React.Fragment>
	);
}

function SaleCard({sale, rens, project, projects, users}) {
	const [rens_, setRens] = useState();
	const [project_, setProject] = useState();
	const {openConfirmation} = useContext(ConfirmationContext);

	const handleClickSave = async () => {
		const mySale = {
			date: convertDate(sale.date),
			buyerName: sale.buyer,
			rens: rens_,
			renIds: rens_.map((ren) => ren.userFid),
			projectName: project_ && project_.projectName,
			atlasProjectName: project_ && project_.atlasProjectName,
			projectFid: project_ && project_.id,
			spaPrice: convertNumber(sale.spaPrice),
			netPrice: convertNumber(sale.netPrice),
			unitNumber: sale.unitNumber,
			unitSize: sale.size,
			package: sale.package,
			remark: sale.remark ? sale.remark : "",
			status: "Booked",
			saleId: await getSaleId(),
			atlasSaleId: sale.id,
		};

		console.log(mySale);
		const salePreview = toHTML(mySale);
		if (!salePreview) {
			notification("Opps", "Please check everything is mapped", "warning");
			return;
		}
		const response = await openConfirmation("Sale Preview", salePreview);
		if (response) {
			const duplicatedSale = await checkSaleDuplication(mySale);
			if (duplicatedSale) {
				const response = await openConfirmation(
					"Duplicated sale found in DJC. Continue creating new Sale?",
					duplicatedSale
				);
				await mapAtlasRenAndProject(mySale);
				if (response) {
					await saveSaleFromAtlas(mySale);
				}
				return;
			}
			await saveSaleFromAtlas(mySale);
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={12}>
				<Card>
					<CardContent>
						<Typography variant="h5">Sale in Atlas</Typography>
						<RenCard rens={rens} users={users} setRens={setRens} />
						<ProjectCard project={project} projects={projects} setProject={setProject} />
						<Others sale={sale} />
					</CardContent>
					<CardActions disableSpacing>
						<Button variant="contained" color="primary" onClick={handleClickSave}>
							Save
						</Button>
					</CardActions>
				</Card>
			</Grid>
		</Grid>
	);
}

function SalesList({users, atlasSales, projects}) {
	return (
		<React.Fragment>
			{!arrayIsEmpty(atlasSales) &&
				atlasSales.map((sale, i) => (
					<SaleCard
						sale={sale}
						rens={sale.rens}
						project={sale.project}
						users={users}
						projects={projects}
						key={sale.id}
					/>
				))}
		</React.Fragment>
	);
}

function AtlasMapping() {
	const [users, setUsers] = useState();
	const [atlasSales, setAtlasSales] = useState([]);
	const [projects, setProjects] = useState();
	const [loading, setLoading] = useState();
	const [tab, setTab] = useState(0);

	useEffect(() => {
		let unsubscribe = () => null;
		const fetchData = async () => {
			setLoading(true);
			const users = await loadUsers();
			const projects = await loadProjects();
			unsubscribe = loadAtlasSales(setAtlasSales);
			setLoading(false);
			setUsers(users);
			setAtlasSales(atlasSales);
			setProjects(projects);
			console.log(atlasSales);
		};
		fetchData();

		return unsubscribe;
	}, []);

	const handleChangeTab = (event, newValue) => {
		setTab(newValue);
		console.log(newValue);
	};

	let filteredSales = [];
	const notMappedSales = atlasSales.filter((sale) => !sale.mapped);
	const mappedSales = atlasSales.filter((sale) => sale.mapped);
	switch (tab) {
		case 0:
			filteredSales = notMappedSales;
			break;
		case 1:
			filteredSales = mappedSales;
		default:
			break;
	}

	return (
		<React.Fragment>
			<Helmet title="Atlas Mapping" />
			<Typography variant="h3" gutterBottom display="inline">
				Atlas Mapping
			</Typography>

			<Breadcrumbs aria-label="Breadcrumb" mt={2}>
				<Link component={NavLink} exact to="/">
					Dashboard
				</Link>
				<Link component={NavLink} exact to="/">
					Pages
				</Link>
				<Typography>Atlas Mapping</Typography>
			</Breadcrumbs>

			<Divider my={6} />
			{loading && <LinearProgress />}

			<Grid container>
				<Grid item xs={12}>
					<Box display="flex" alignItems="center">
						<Tabs
							value={tab}
							onChange={handleChangeTab}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="auto"
						>
							<Tab
								label={
									<Badge badgeContent={notMappedSales.length} color="primary" max={999}>
										Not Mapped
									</Badge>
								}
							/>
							<Tab
								label={
									<Badge badgeContent={mappedSales.length} color="primary" max={999}>
										Mapped
									</Badge>
								}
							/>
						</Tabs>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<SalesList users={users} atlasSales={filteredSales} projects={projects} />
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

export default AtlasMapping;
