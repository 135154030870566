import React, {useState, useEffect, useContext} from "react";
import ReactDOMServer from "react-dom/server";
import styled from "styled-components/macro";
import {withRouter} from "react-router-dom";
import {loadUser} from "../functions/loadUser";
import {AuthContext} from "../context/AuthContext";
import {Box, Button as MuiButton, Divider, Grid, Icon, TextField, Typography} from "@material-ui/core";
import {ConfirmationContext} from "../context/ConfirmationContext";
import {sendClouldMail} from "../functions/sendCloudMail";
import {dateFormat} from "../functions/date-format";
import {numberWithDecimals} from "../functions/number-formating";
import {convertDate} from "../functions/convertDate";
import {spacing} from "@material-ui/system";
import {arrayIsEmpty} from "../functions/arrayIsEmpty";
import {notification} from "../functions/notification";

const Button = styled(MuiButton)(spacing);

const INITIAL_STATE = {
	date: dateFormat(new Date()),
	toEmails: [],
	message: "",
	fromEmail: "davechong@eliteone.com.my",
	replyTo: "",
	saleReportObj: "",
	ccEmails: [],
	paramsId: "",
	sale: "",
};

const adminEmails = [
	"klproject@iqiglobal.com",
	"penangproject@iqiglobal.com",
	"sabahproject@iqiglobal.com",
	"sarawakproject@iqiglobal.com",
	"johorproject@iqiglobal.com",
	"perakproject@iqiglobal.com",
	"melakaproject@iqiglobal.com",
];

const SaleReport = ({sale, match}) => {
	const {user} = useContext(AuthContext);
	const [state, setState] = useState(INITIAL_STATE);
	const {openConfirmation} = useContext(ConfirmationContext);
	const [email, setEmail] = useState("");

	useEffect(() => {
		console.log(sale);
	}, []);

	useEffect(
		() => {
			const fetchData = async () => {
				const ccEmails = await Promise.all(
					sale.rens.map(async (ren) => {
						let user = await loadUser(ren.userFid);
						if (user) {
							if (user.email) {
								return user.email;
							}
							return null;
						} else {
							return null;
						}
					})
				);

				const saleReportObj = getSaleObject(sale);

				setState({
					...state,
					saleReportObj: saleReportObj,
					ccEmails: ccEmails,
					replyTo: user.email,
					sale: sale,
				});
			};

			fetchData();
		},
		[sale]
	);

	const sendEmail = async (e) => {
		e.preventDefault();
		const {date, toEmails, message, fromEmail, saleReportObj, ccEmails, replyTo} = state;
		if (arrayIsEmpty(toEmails)) {
			notification("Empty email", "Please add emails", "warning");
		}
		const saleReportEmail = ReactDOMServer.renderToStaticMarkup(saleReportObj);

		console.log(ccEmails);

		const emailToBeSent = {
			to: toEmails,
			from: fromEmail,
			cc: ccEmails,
			replyTo: replyTo,
			message: {
				subject: state.sale.projectName + " | " + state.sale.unitNumber + " | Sale Report Status Update ",
				html: saleReportEmail + `<p><b>${dateFormat(date)}</b></p><Grid><h3>${message}</h3></Grid>`,
			},
		};
		console.log(state);
		console.log(emailToBeSent);

		const response = await openConfirmation("Send email", "Confirm sending email?");
		if (response) {
			sendClouldMail(emailToBeSent);
			setState({...state, message: ""});
		}
	};

	const handleChange = (event) => {
		const {id, value} = event.target;
		setState({...state, [id]: value});
	};

	const {message, saleReportObj} = state;

	if (!saleReportObj) {
		return <Grid>No Sale Report</Grid>;
	}

	const handleClickEmail = (email) => {
		let emails = [...state.toEmails];
		if (!emails.includes(email)) emails.push(email);
		setState({...state, toEmails: emails});
	};

	const handleSubmitEmail = (e) => {
		e.preventDefault();
		if (!email) return;
		const emails = [...state.toEmails];
		if (!emails.includes(email)) emails.push(email);

		setState({...state, toEmails: emails});
		setEmail("");
	};

	const handleDeleteEmail = (email) => {
		let newEmails = [...state.toEmails];
		newEmails = newEmails.filter((doc) => doc !== email);
		setState({...state, toEmails: newEmails});
	};

	return (
		<Box m={6}>
			<Grid container spacing={1} justify="center">
				<Grid container item xs={12} md={7}>
					<Grid item xs={12} align="center">
						<h1>Sale Report</h1>
					</Grid>
					<Grid item container xs={12} justify="center">
						<Grid item align="left">
							{saleReportObj}
						</Grid>
					</Grid>
					<Grid item container xs={12}>
						<Box my={3}>
							<Divider />
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Grid>{message}</Grid>
					</Grid>
				</Grid>
				<Grid container item xs={12} md={7}>
					<Grid item xs={12}>
						<Box display="flex" alignItems="center" flexWrap="wrap">
							{state.toEmails.map((email, i) => (
								<Box display="flex" alignItems="center" key={i}>
									<Typography>{email}</Typography>
									<Icon
										onClick={() => handleDeleteEmail(email)}
										style={{cursor: "pointer", marginRight: "10px"}}
									>
										clear
									</Icon>
								</Box>
							))}
						</Box>
					</Grid>
				</Grid>

				<Grid item container xs={12} md={7} justify="center">
					<form style={{width: "100%"}} onSubmit={handleSubmitEmail}>
						<Grid container alignItems="center">
							<Grid item xs={10}>
								<TextField
									type="email"
									variant="outlined"
									name="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									fullWidth
									label="email"
									required
								/>
							</Grid>
							<Grid item xs={2}>
								<Button
									size="small"
									variant="contained"
									color="secondary"
									type="submit"
									style={{marginLeft: "10px"}}
								>
									Add
								</Button>
							</Grid>
						</Grid>
					</form>
				</Grid>
				<Grid item xs={12} md={7}>
					<form onSubmit={sendEmail} style={{width: "100%"}}>
						<Box my={3}>
							<TextField
								variant="outlined"
								type="textarea"
								name="message"
								id="message"
								value={message}
								onChange={handleChange}
								label="Message"
								required
								fullWidth
							/>
						</Box>

						<Button my={2} variant="contained" color="primary" type="submit">
							Send Email
						</Button>
					</form>
				</Grid>
				<Grid item container xs={12} md={7}>
					<Grid item>
						<p />
						<Grid>
							<b>Emails References</b>
						</Grid>
						{adminEmails.map((adminEmail, i) => (
							<Grid key={i}>
								<Typography style={{cursor: "pointer"}} onClick={() => handleClickEmail(adminEmail)}>
									{adminEmail}
								</Typography>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default withRouter(SaleReport);

function getSaleObject(sale) {
	return (
		<div>
			<div>Date: {dateFormat(convertDate(sale.date))}</div>
			{sale.rens.map((ren, i) => {
				return (
					<div key={`REN${i + 1}`}>
						REN {i + 1}: {ren.renName} ({ren.renPercentage}%)
					</div>
				);
			})}
			<div>Project: {sale.projectName}</div>
			<div>Unit No: {sale.unitNumber}</div>
			<div>Size: {sale.unitSize} sf</div>
			<div>Buyer: {sale.buyerName}</div>
			<div>SPA Price: RM {numberWithDecimals(sale.spaPrice)}</div>
			<div>Nett Price: RM {numberWithDecimals(sale.netPrice)}</div>
			<div>Package: {sale.package}</div>
			<div>Remark: {sale.remark}</div>
			<div>Team: Eliteone</div>
		</div>
	);
}
