import React, {useEffect, useState} from "react";
import styled, {withTheme} from "styled-components/macro";
import withWidth from "@material-ui/core/withWidth";
import {darken} from "polished";
import EliteoneLogo from "../assets/EliteoneLogo.png";
import EliteoneLogoWhite from "../assets/EliteoneLogoWhite.png";
import LegacyLogo from "../assets/LegacyLogo.png";
import LegacyWhiteLogo from "../assets/LegacyLogoWhite.png";
import DJCLogo from "../assets/DJCLogoGrey.png";

import {
	Grid,
	Hidden,
	InputBase,
	AppBar as MuiAppBar,
	IconButton as MuiIconButton,
	Toolbar,
	Box,
	Link,
} from "@material-ui/core";

import {Menu as MenuIcon} from "@material-ui/icons";

import NotificationsDropdown from "./NotificationsDropdown";
import MessagesDropdown from "./MessagesDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import UserDropdown from "./UserDropdown";
import Settings from "./Settings";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
	border-radius: 2px;
	background-color: ${(props) => props.theme.header.background};
	display: none;
	position: relative;
	width: 100%;
	&:hover {
		background-color: ${(props) => darken(0.05, props.theme.header.background)};
	}
	${(props) => props.theme.breakpoints.up("md")} {
		display: block;
	}
`;

const SearchIconWrapper = styled.div`
	width: 50px;
	height: 100%;
	position: absolute;
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		width: 22px;
		height: 22px;
	}
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

function AppBarComponent({width, onDrawerToggle}) {
	const [screen, setScreen] = useState("large");
	const [logo, setLogo] = useState(DJCLogo);
	const [teamLogo, setTeamLogo] = useState("DJC");
	const currentTheme = useSelector((state) => state.themeReducer);

	useEffect(
		() => {
			const host = window.location.hostname;
			console.log(currentTheme.currentTheme);
			if (host === "legacyadmin.djcsystem.com") {
				setTeamLogo("Legacy");
				if (currentTheme.currentTheme === "DARK") {
					setLogo(LegacyWhiteLogo);
					return;
				}
				setLogo(LegacyLogo);
			}

			if (host === "admin.eliteonegroup.com" || host === "localhost") {
				setTeamLogo("Eliteone");
				if (currentTheme.currentTheme === "DARK") {
					setLogo(EliteoneLogoWhite);
					return;
				}
				setLogo(EliteoneLogo);
			}
		},
		[currentTheme]
	);

	useEffect(
		() => {
			if (width === "sm" || width === "xs") {
				setScreen("small");
			} else {
				setScreen("large");
			}
		},
		[width]
	);

	const clickLogo = () => {};
	return (
		<React.Fragment>
			<AppBar position="sticky" elevation={0}>
				<Toolbar>
					<Grid container alignItems="center">
						<Hidden mdUp>
							<Grid item>
								<IconButton color="inherit" aria-label="Open drawer" onClick={onDrawerToggle}>
									<MenuIcon />
								</IconButton>
							</Grid>
						</Hidden>

						<Grid item xs>
							<Link component={NavLink} exact to="/">
								<img
									width={teamLogo === "Legacy" ? "70px" : "140px"}
									alt="logo"
									src={logo}
									onClick={clickLogo}
									style={{marginLeft: screen === "large" ? "30px" : "0px"}}
								/>
							</Link>
						</Grid>
						<Grid item>
							<Box display="flex" alignItems="center">
								<UserDropdown />
								<Settings />
							</Box>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		</React.Fragment>
	);
}

export default withTheme(withWidth()(AppBarComponent));
