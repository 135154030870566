import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	Icon,
	makeStyles,
	styled,
	TextField as MuiTextField,
	Typography,
} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import {spacing} from "@material-ui/system";
import moment from "moment";
import React from "react";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../context/AuthContext";
import {ConfirmationContext} from "../context/ConfirmationContext";
import {ListContext} from "../context/ListContext";
import {SalesContext} from "../context/SalesContext";
import {convertDate} from "../functions/convertDate";
import {deleteSale} from "../functions/deleteSale";
import {loadProjects} from "../functions/loadProjects";
import {loadUsers} from "../functions/loadUsers";
import {notification} from "../functions/notification";
import {saveSale} from "../functions/saveSale";

const TextField = styled(MuiTextField)(spacing);

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
}));

export default function SalePage({sale, handleClose}) {
	const classes = useStyles();

	const {user} = useContext(AuthContext);
	const [state, setState] = useState(sale);
	const [loading, setLoading] = useState(false);
	const {openGlobalList} = useContext(ListContext);
	const {downlines, setDownlines, users, setUsers, projects, setProjects} = useContext(SalesContext);
	const {openConfirmation} = useContext(ConfirmationContext);

	useEffect(() => {
		console.log(sale);
	}, []);

	const handleChange = (e) => {
		const {name, value} = e.target;
		setState({...state, [name]: value});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setLoading("Saving...");
		await saveSale(state);
		setLoading(false);
	};

	const handleDeleteSale = async () => {
		const response = await openConfirmation("Delete sale", "Are you sure you want to delete the sale?");
		if (response) {
			await deleteSale(sale);
			handleClose();
		}
	};

	const handleClickEdit = async () => {
		setLoading(true);
		let allProjects = projects;
		if (!allProjects) {
			allProjects = await loadProjects();
			setProjects(allProjects);
		}
		const response = await openGlobalList(allProjects, "Select project", "projectName", "briefcase", false, true);
		if (response) {
			setState({...state, projectFid: response.id, projectName: response.projectName});
		}
		setLoading(false);
	};

	const handleClickRen = async (i) => {
		setLoading(true);
		let allUsers = users;
		if (!users) {
			allUsers = await loadUsers();
			setUsers(allUsers);
		}
		const response = await openGlobalList(allUsers, "Select upline", "displayName", "face", false, true);
		if (response) {
			let rens = [...state.rens];
			rens[i] = {...rens[i], userFid: response.id, renName: response.displayName};
			setState({...state, rens: rens});
		}
		setLoading(false);
	};

	const handleChangePercentage = (e, i) => {
		let rens = [...state.rens];
		rens[i] = {...rens[i], renPercentage: e.target.value};
		setState({...state, rens: rens});
	};

	const handleAdd = (i) => {
		let rens = [...state.rens];
		const ren = {renName: "", userFid: "", renPercentage: ""};
		rens.splice(i + 1, 0, ren);
		setState({...state, rens: rens});
	};

	const handleRemove = (i) => {
		let rens = [...state.rens];
		rens.splice(i, 1);
		setState({...state, rens: rens});
	};

	return (
		<Box m={6}>
			<Grid container>
				<form style={{width: "100%"}} onSubmit={handleSubmit}>
					<Grid container item xs={12} md={7} alignItems="center" spacing={1}>
						<Grid item xs={12}>
							<TextField
								m={1}
								variant="outlined"
								label={"Date"}
								value={moment(convertDate(state.date)).format("YYYY-MM-DD")}
								name="date"
								onChange={handleChange}
								fullWidth
								required
								type="date"
								InputLabelProps={{shrink: true}}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								m={1}
								variant="outlined"
								label={"ID"}
								value={state.saleId}
								name="saleId"
								onChange={handleChange}
								fullWidth
								disabled={user.role!=="Super Admin"}
							/>
						</Grid>
						{user.role === "Super Admin" && (
							<Grid item xs={12}>
								<TextField
									m={1}
									variant="outlined"
									label={"Fid"}
									value={state.id}
									name="id"
									onChange={handleChange}
									fullWidth
									disabled
								/>
							</Grid>
						)}
						<Grid item xs={11}>
							<TextField
								m={1}
								variant="outlined"
								label={"Project"}
								value={state.projectName}
								name="projectName"
								onChange={handleChange}
								fullWidth
								InputLabelProps={{shrink: true}}
								disabled
							/>
						</Grid>
						<Grid item xs={1}>
							<Box ml={1} alignItems="center" justifyContent="center">
								<Icon style={{cursor: "pointer"}} onClick={handleClickEdit}>
									edit
								</Icon>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<TextField
								m={1}
								variant="outlined"
								label={"Unit Number"}
								value={state.unitNumber}
								name="unitNumber"
								onChange={handleChange}
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								m={1}
								variant="outlined"
								label={"Size"}
								value={state.unitSize}
								name="unitSize"
								onChange={handleChange}
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								m={1}
								variant="outlined"
								label={"Buyer"}
								value={state.buyerName}
								name="buyer"
								onChange={handleChange}
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								m={1}
								variant="outlined"
								label={"Remark"}
								value={state.remark}
								name="remark"
								onChange={handleChange}
								fullWidth
							/>
						</Grid>

						{state.rens &&
							state.rens.map((ren, i) => (
								<React.Fragment key={i}>
									<Grid item xs={6}>
										<TextField
											m={1}
											variant="outlined"
											label={`REN${i + 1}`}
											value={state.rens[i].renName}
											fullWidth
											disabled
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											m={1}
											variant="outlined"
											label={`REN${i + 1}%`}
											value={state.rens[i].renPercentage}
											fullWidth
											onChange={(e) => handleChangePercentage(e, i)}
										/>
									</Grid>
									<Grid item xs={3}>
										<Box display="flex" alignItems="center" justifyContent="flex-end">
											<Icon
												style={{cursor: "pointer", marginLeft: "5px"}}
												onClick={() => handleClickRen(i)}
											>
												edit
											</Icon>
											<Icon style={{cursor: "pointer"}} onClick={() => handleAdd(i)}>
												add
											</Icon>
											{i !== 0 && (
												<Icon style={{cursor: "pointer"}} onClick={() => handleRemove(i)}>
													remove
												</Icon>
											)}
										</Box>
									</Grid>
								</React.Fragment>
							))}

						<Grid item xs={12}>
							<TextField
								m={1}
								variant="outlined"
								label={"Status"}
								value={state.status}
								name="status"
								onChange={handleChange}
								fullWidth
								disabled
							/>
						</Grid>

						<Grid item xs={12}>
							<Box m={1}>
								<Button variant="contained" color="primary" type="submit">
									Update
								</Button>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Box m={1}>
								<Button
									variant="outlined"
									size="small"
									style={{color: red[500]}}
									onClick={handleDeleteSale}
								>
									Delete Sale
								</Button>
							</Box>
						</Grid>
						<Backdrop className={classes.backdrop} open={loading}>
							<CircularProgress color="inherit" />{" "}
							<Typography style={{marginLeft: "15px"}}>{loading}</Typography>
						</Backdrop>
					</Grid>
				</form>
			</Grid>
		</Box>
	);
}
