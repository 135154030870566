import { notification } from "../functions/notification";
import { firestore } from "../firebase/firebase-utils";

export async function importCollection(data, collectionName) {
	try {
		let ref = () => null;
		const response = new Promise((resolve, reject) => {
			ref = resolve;
		});
		data.forEach(async (doc, i) => {
			const docRef = firestore.collection(collectionName).doc(doc.id);
			await firestore.runTransaction(async (transaction) => {
				const dataDoc = await transaction.get(docRef);
				if (dataDoc.exists) {
					console.log("Data already exists in Database " + doc.id);
				} else {
					transaction.set(docRef, doc, { merge: true });
					console.log("Data created in Firestore: " + doc.id);
				}
			});
			if (i === data.length - 1) {
				ref("done");
			}
		});
		return response;
	} catch (error) {
		notification("Opps", error.message, "warning");
	}
}
