import React from "react";
import styled from "styled-components/macro";
import {spacing} from "@material-ui/system";
import {Link} from "react-router-dom";

import {AppBar, Box, Button as MuiButton, Container, Grid, Hidden, Toolbar} from "@material-ui/core";

import BrandIcon from "../../../assets/DJCLogoGrey.png";

const Button = styled(MuiButton)(spacing);

const Brand = styled.div`
	font-size: ${(props) => props.theme.typography.h5.fontSize};
	font-weight: ${(props) => props.theme.typography.fontWeightMedium};
	font-family: ${(props) => props.theme.typography.fontFamily};
`;

const AppBarComponent = () => (
	<React.Fragment>
		<AppBar position="relative" color="transparent" elevation={0}>
			<Toolbar>
				<Container>
					<Grid container alignItems="center">
						<Grid item>
							<Box display="flex" alignItems="center">
								<img src={BrandIcon} width="130px" />
							</Box>
						</Grid>
						<Grid item xs />
					</Grid>
				</Container>
			</Toolbar>
		</AppBar>
	</React.Fragment>
);

export default AppBarComponent;
