import React, {useContext, useState} from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {withRouter} from "react-router";
import {notification} from "../functions/notification";
import LocalCafeIcon from "@material-ui/icons/LocalCafe";
import {signUp} from "../functions/signUp";
import {Backdrop, Icon, InputAdornment, CircularProgress} from "@material-ui/core";
import {ListContext} from "../context/ListContext";
import {SalesContext} from "../context/SalesContext";
import {loadUsers} from "../functions/loadUsers";
import FullDialog from "../components/FullDialog";
import RegisterTelegramStepper from "../components/RegisterTelegramStepper";
import {loadLocations} from "../functions/loadLocations";
import {InputContext} from "../context/InputContext";
import {AddLocation} from "@material-ui/icons";
import {addLocation} from "../functions/addLocation";

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"Copyright © "}
			<Link color="inherit">DJC Venture</Link> {new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
}));

const SignUp = ({history}) => {
	const classes = useStyles();

	const INITIAL_DATA = {
		firtName: "",
		lastName: "",
		displayName: "",
		immediateUpline: "",
		immediateUplineFid: "",
		email: "",
		password: "",
		confirmPassword: "",
		chatId: "",
		location: "",
	};

	const [state, setState] = useState(INITIAL_DATA);
	const [loading, setLoading] = useState(false);
	const {openGlobalList} = useContext(ListContext);
	const {users, setUsers, locations, setLocations} = useContext(SalesContext);
	const [openTelegram, setOpenTelegram] = useState(false);
	const {openDialog} = useContext(InputContext);

	const handleChange = (event) => {
		const {name, value} = event.target;
		setState({
			...state,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (state.password !== state.confirmPassword) {
			notification("Opps!", "Password is not the same as Confirm Password. Please reenter.", "danger");
			return;
		}
		if (state.secretCode !== "4") {
			notification("Opps!", "Wrong secret code. Please reenter.", "danger");
			return;
		}
		setLoading(true);
		const response = await signUp({
			...state,
			firstName: state.firstName,
			lastName: state.lastName,
			email: state.email,
			password: state.password,
			phone: state.phone,
		});
		if (response !== "error") {
			setTimeout(() => {
				history.push("/");
				setLoading(false);
			}, 1000);
		} else {
			setLoading(false);
		}
	};

	const handleOpenUpline = async () => {
		let myUsers = users;
		if (!users) {
			setLoading(true);
			myUsers = await loadUsers();
			setUsers(myUsers);
			setLoading(false);
		}
		const response = await openGlobalList(myUsers, "Select upline", "displayName", "face", false, true);
		if (response) {
			setState({...state, immediateUpline: response.displayName, immediateUplineFid: response.id});
		}
	};

	const handleClickTelegram = () => {
		setOpenTelegram(true);
	};

	const handleCloseTelegram = (value) => {
		if (value) {
			setState({...state, chatId: value});
			setOpenTelegram(false);
		} else {
			setOpenTelegram(false);
		}
	};

	const handleClickLocation = async () => {
		let myLocations = locations;
		if (!myLocations) {
			myLocations = await loadLocations();
			setLocations(myLocations);
		}
		const response = await openGlobalList(myLocations, "Select location", "name", "face", true, true);
		if (response === "addAccount") {
			const location = await openDialog("Add Location", "Please type your location name", "location", "");
			if (response) setState({...state, location: location});
			await addLocation(location);
			setLocations([...myLocations, {name: location}]);
			return;
		}
		if (response) {
			setState({...state, location: response.name});
		}
	};

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LocalCafeIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Sign up
				</Typography>
				<form className={classes.form} onSubmit={handleSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField
								autoComplete="fname"
								name="firstName"
								variant="outlined"
								required
								fullWidth
								id="firstName"
								label="First Name"
								value={state.firstName}
								autoFocus
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="lastName"
								label="Last Name"
								name="lastName"
								autoComplete="lname"
								value={state.lastName}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="displayName"
								label="Display Name"
								name="displayName"
								autoComplete="dname"
								value={state.displayName}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								value={state.email}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								fullWidth
								required
								name="phone"
								id="phone"
								label="Phone Number (e.g. +60121111111)"
								value={state.phone}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								value={state.location}
								label="Location"
								fullWidth
								required
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<Icon style={{cursor: "pointer"}} onClick={handleClickLocation}>
												arrow_drop_down
											</Icon>
										</InputAdornment>
									),
								}}
								InputLabelProps={{shrink: true}}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								variant="outlined"
								value={state.immediateUpline}
								label="Immediate Upline"
								fullWidth
								required
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<Icon style={{cursor: "pointer"}} onClick={handleOpenUpline}>
												arrow_drop_down
											</Icon>
										</InputAdornment>
									),
								}}
								InputLabelProps={{shrink: true}}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								value={state.chatId}
								label="Telegram Chat Id"
								fullWidth
								required
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<Icon style={{cursor: "pointer"}} onClick={handleClickTelegram}>
												arrow_drop_down
											</Icon>
										</InputAdornment>
									),
								}}
								InputLabelProps={{shrink: true}}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
								value={state.password}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								name="confirmPassword"
								label="Confirm Password"
								type="password"
								id="confirmPassword"
								value={state.confirmPassword}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								name="secretCode"
								label="What is 2 x 2?"
								id="secretCode"
								value={state.secretCode}
								onChange={handleChange}
							/>
						</Grid>
					</Grid>

					<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
						Sign Up
					</Button>
					<Grid container justify="flex-end">
						<Grid item>
							<Link
								href="#"
								variant="body2"
								onClick={() => {
									history.push("/sign-in");
								}}
							>
								Already have an account? Sign in
							</Link>
						</Grid>
					</Grid>
				</form>
			</div>
			<Box mt={5}>
				<Copyright />
			</Box>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" /> <Typography style={{marginLeft: "15px"}}>{loading}</Typography>
			</Backdrop>
			<FullDialog
				open={openTelegram}
				handleClose={() => handleCloseTelegram()}
				title={"Register Telegram"}
				component={<RegisterTelegramStepper handleClose={handleCloseTelegram} />}
			/>
		</Container>
	);
};

export default withRouter(SignUp);
