import { firestore } from "../firebase/firebase-utils";
import { mapSnapshot } from "./mapSnapshot";

export async function loadProjects() {
  try {
    const snapshot = await firestore.collection("projects").get();
    const projects = mapSnapshot(snapshot);
    return projects;
  } catch (error) {
    console.log(error);
  }
}
