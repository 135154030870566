import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	Grow,
	Icon,
	InputAdornment,
	TextField,
	Typography,
	Chip as MuiChip,
} from "@material-ui/core";
import styled from "styled-components/macro";
import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import {SalesContext} from "../context/SalesContext";
import {convertDate} from "../functions/convertDate";
import {blue, green, orange, red} from "@material-ui/core/colors";
import FullDialog from "../components/FullDialog";
import SaleReport from "../components/SaleReport";
import {AuthContext} from "../context/AuthContext";
import SalePage from "./SalePage";

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};

  span {
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

export default function SalesFlexbox() {
	const {sales, loading, startDate, setStartDate, endDate} = useContext(SalesContext);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [search, setSearch] = useState("");
	const [openReport, setOpenReport] = useState(false);
	const [openUpdate, setOpenUpdate] = useState(false);
	const [sale, setSale] = useState();
	const {user} = useContext(AuthContext);
	const [findError, setFindError] = useState(false);
	const [year, setYear] = useState(startDate.getFullYear());
	const [findDuplicated, setFindDuplicated] = useState(false);

	useEffect(
		() => {
			if (sales) {
				console.log(sales.length);
			}
		},
		[sales]
	);

	useEffect(
		() => {
			if (year >= startDate.getFullYear()) return;
			const newStartDate = new Date(startDate.getFullYear() - 1, 0, 1);
			setStartDate(newStartDate);
		},
		[year]
	);

	const handleChange = (e) => {
		setSearch(e.target.value);
		setPage(0);
	};

	const handleClickReport = (sale) => {
		setSale(sale);
		setOpenReport(true);
	};

	const handleCloseReport = () => {
		setOpenReport(false);
	};

	const handleClickUpdate = (sale) => {
		setSale(sale);
		setOpenUpdate(true);
	};

	const handleCloseUpdate = () => {
		setOpenUpdate(false);
	};

	const statusColor = (status) => {
		switch (status) {
			case "Booked":
				return orange[700];
			case "Cancelled":
				return red[500];
			case "Converted":
				return green[500];
			default:
				break;
		}
	};

	// Filtered sales
	let filteredSales = [];
	if (sales) filteredSales = sales;
	filteredSales = filteredSales.filter((sale) => {
		try {
			let choose = false;
			if (sale.unitNumber && sale.unitNumber.toString().toLowerCase().includes(search.toLowerCase()))
				choose = true;
			if (sale.projectName && sale.projectName.toLowerCase().includes(search.toLowerCase())) choose = true;
			if (sale.buyerName && sale.buyerName.toString().toLowerCase().includes(search.toLowerCase())) choose = true;
			if (sale.status && sale.status.toLowerCase().includes(search.toLowerCase())) choose = true;
			if (sale.saleId && sale.saleId.toString().toLowerCase().includes(search.toLowerCase())) choose = true;
			sale.rens.forEach((ren) => {
				if (ren.renName && ren.renName.toLowerCase().includes(search.toLowerCase())) choose = true;
			});
			return choose;
		} catch (err) {
			console.log(sale);
			console.log(err);
			return false;
		}
	});

	if (findError) {
		filteredSales = filteredSales.filter((sale) => {
			let display = false;
			if (sale.teamIds) {
				sale.rens.forEach((ren) => {
					if (!sale.teamIds.includes(ren.userFid)) {
						display = true;
						return;
					}
				});
			} else {
				display = true;
			}

			return display;
		});
	}

	if (findDuplicated) {
		let array = [];
		let duplicated = [];
		filteredSales.forEach((sale) => {
			const found = array.find((saleId) => saleId === sale.saleId);
			if (!found) {
				array.push(sale.saleId);
			} else {
				duplicated.push(sale.saleId);
			}
		});
		filteredSales = filteredSales.filter((sale) => {
			let display = false;
			duplicated.forEach((saleId) => {
				if (saleId === sale.saleId) display = true;
			});
			return display;
		});

	}
	

	const totalPages = Math.floor((filteredSales.length - 1) / rowsPerPage) + 1;
	filteredSales = filteredSales.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

	return (
		<React.Fragment>
			<Grid container spacing={1} style={{overflowX: "hidden"}}>
				{user.role === "Super Admin" && (
					<Grid item xs={12}>
						<Box display="flex" alignItems="center">
							<Button variant="outlined" onClick={() => setFindError(!findError)}>
								Find Errors
							</Button>
							<Typography style={{marginLeft: "15px"}}>Find Error: {findError.toString()}</Typography>
						</Box>
						<Box display="flex" alignItems="center">
							<Button variant="outlined" onClick={() => setFindDuplicated(!findDuplicated)}>
								Find Duplicated
							</Button>
							<Typography style={{marginLeft: "15px"}}>
								Find Duplicated: {findDuplicated.toString()}
							</Typography>
						</Box>
					</Grid>
				)}
				<Grid item xs={12}>
					<Box display="flex" alignItems="center">
						<Icon style={{cursor: "pointer"}} onClick={() => setYear(year - 1)} marginRight="10px">
							arrow_back
						</Icon>
						<Typography>
							Load Sales: Year {year} to {endDate.getFullYear()}
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12}>
					{PagesBox(page, setPage, totalPages)}
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant="outlined"
						value={search}
						onChange={handleChange}
						label="search"
						fullWidth
						InputProps={{
							endAdornment: search && (
								<InputAdornment position="end">
									<Icon style={{cursor: "pointer"}} onClick={() => setSearch("")}>
										clear
									</Icon>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				{loading && Loading(loading)}
				<Grid item xs={12}>
					<Box mt={1} mb={1}>
						<Divider />
					</Box>
				</Grid>
				<Grid container spacing={2}>
					{filteredSales &&
						filteredSales.map((sale, i) => (
							<Grid container item xs={12} md={5}>
								<Grid item style={{width: "50px"}}>
									<Typography variant="body2">{sale.saleId}</Typography>
								</Grid>
								<Grid item style={{width: "100px"}}>
									<Typography variant="body2">
										{moment(convertDate(sale.date)).format("YYYY-MM-DD")}
									</Typography>
								</Grid>
								<Grid item xs>
									<Box display="flex" alignItems="center">
										<Typography variant="body2">{sale.projectName}</Typography>
										<Typography variant="body2" style={{marginLeft: "5px"}}>
											{" "}
											| {sale.unitNumber}
										</Typography>
									</Box>
								</Grid>

								<Grid item xs={12}>
									<Typography variant="body2">Buyer: {sale.buyerName}</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body2">
										SPA Price: {parseInt(sale.spaPrice).toLocaleString()}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body2">
										Net Price: {parseInt(sale.netPrice).toLocaleString()}
									</Typography>
								</Grid>
								{sale.rens.map((ren, i) => (
									<Grid container item xs={12} md={7} key={`${i}${ren.userFid}`}>
										<Grid item style={{width: "200px"}}>
											<Typography variant="body2">
												REN {i + 1}: {ren.renName}
											</Typography>
										</Grid>
										<Grid item xs>
											<Typography variant="body2">{ren.renPercentage}%</Typography>
										</Grid>
										{user.role === "Super Admin" && (
											<Grid item xs zeroMinWidth>
												<Typography
													variant="body2"
													noWrap
													style={{color: !sale.teamIds && red[500]}}
												>
													{sale.teamIds ? sale.teamIds.find((id) => id === ren.userFid) : "X"}
												</Typography>
											</Grid>
										)}
									</Grid>
								))}
								<Grid item xs={12} md={7} align="right">
									<Box display="flex" alignItems="center" justifyContent="flex-end">
										<Chip
											label={"Report"}
											style={{
												backgroundColor: green[700],
												marginRight: "5px",
												cursor: "pointer",
											}}
											onClick={() => handleClickReport(sale)}
										/>
										<Chip
											label={"Update"}
											style={{
												backgroundColor: blue[700],
												marginRight: "5px",
												cursor: "pointer",
											}}
											onClick={() => handleClickUpdate(sale)}
										/>
										<Chip
											label={sale.status}
											style={{backgroundColor: statusColor(sale.status), cursor: "pointer"}}
										/>
									</Box>
								</Grid>
								<Grid item xs={12} md={7}>
									<Box mt={1} mb={1}>
										<Divider />
									</Box>
								</Grid>
							</Grid>
						))}
				</Grid>
				<Grid item xs={12}>
					{PagesBox(page, setPage, totalPages)}
				</Grid>
			</Grid>
			<FullDialog
				open={openReport}
				handleClose={handleCloseReport}
				title={"Sale Report"}
				component={<SaleReport sale={sale} />}
			/>
			<FullDialog
				open={openUpdate}
				handleClose={handleCloseUpdate}
				title={"Update Sale"}
				component={<SalePage sale={sale} handleClose={handleCloseUpdate} />}
			/>
		</React.Fragment>
	);
}

function Loading(loading) {
	return (
		<Box display="flex" alignItems="center">
			<CircularProgress />
			<Typography variant="body2" style={{margin: "15px"}}>
				{loading}
			</Typography>
		</Box>
	);
}

function PagesBox(page, setPage, totalPages) {
	return (
		<Box display="flex" alignItems="center">
			<Button
				variant="outlined"
				color="inherit"
				disabled={page === 0}
				onClick={() => {
					setPage(page - 1);
					window.scrollTo(0, 0);
				}}
			>
				Last Page
			</Button>
			<Typography variant="body2" style={{margin: "15px"}}>
				Page {page + 1} of {totalPages}
			</Typography>
			<Button
				variant="outlined"
				color="inherit"
				disabled={page + 1 === totalPages}
				onClick={() => {
					setPage(page + 1);
					window.scrollTo(0, 0);
				}}
			>
				Next Page
			</Button>
		</Box>
	);
}
