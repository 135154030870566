import {firestore} from "../firebase/firebase-utils";
import {flattenSales} from "./flattenSales";
import {getFirestoreData} from "./getFirestoreData";
import {mapSnapshot} from "./mapSnapshot";

export async function loadSales(startDate, endDate, setSales, setLoading, user, downlines, setFSales) {
	try {
		if (downlines) {
			setLoading("Loading sales...");
			return firestore
				.collection("sales")
				.where("date", ">=", startDate)
				.where("date", "<=", endDate)
				.where("teamIds", "array-contains", user.id)
				.orderBy("date", "desc")
				.onSnapshot((snapshot) => {
					if (snapshot.empty) {
						setSales([]);
						setFSales([]);
						return;
					} else {
						const sales = mapSnapshot(snapshot);
						setSales(sales);
						const fSales = flattenSales(sales, downlines);
						setFSales(fSales);
					}
				});
		}
	} catch (error) {
		console.log(error);
		setLoading(false);
	}
}
