import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function FormDialog({open, setOpen, title, text, label, onSave, initialValue}) {
	const [value, setValue] = useState(initialValue);

	const handleCloseCancel = () => {
		setOpen(false);
	};
	const handleCloseSave = () => {
		onSave(value);
		setValue("");
		setOpen(false);
	};

	useEffect(
		() => {
			setValue(initialValue);
		},
		[initialValue]
	);

	return (
		<Dialog open={open} onClose={handleCloseSave} fullWidth>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{text}</DialogContentText>
				<TextField
					margin="dense"
					id="name"
					label={label}
					fullWidth
					value={value}
					onChange={(e) => {
						setValue(e.target.value);
					}}
					multiline
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseCancel} color="primary">
					Cancel
				</Button>
				<Button onClick={handleCloseSave} color="primary">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
