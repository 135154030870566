import React, {useEffect, useState} from "react";
import {arrayIsEmpty} from "../functions/arrayIsEmpty";
import {flattenSales} from "../functions/flattenSales";
import {getSalesByMonth} from "../functions/getSalesByMonth";
import {loadAllSales} from "../functions/loadAllSales";
import {loadDownlines} from "../functions/loadDownlines";
import {loadLastYearSales} from "../functions/loadLastYearSales";
import {loadSales} from "../functions/loadSales";

export const SalesContext = React.createContext();

const now = new Date();
let lastYear = new Date(now.getFullYear() - 1, 0, 1, 0, 0, 0, 0);

export const SalesProvider = ({children, user}) => {
	const [sales, setSales] = useState();
	const [fSales, setFSales] = useState();
	const [salesByMonth, setSalesByMonth] = useState();
	const [downlines, setDownlines] = useState();
	const [users, setUsers] = useState();
	const [loading, setLoading] = useState(false);
	const [startDate, setStartDate] = useState(lastYear);
	const [endDate, setEndDate] = useState(new Date(new Date().setHours(23, 59, 59, 59)));
	const [projects, setProjects] = useState();
	const [locations, setLocations] = useState();

	useEffect(
		() => {
			const fetchData = async () => {
				setLoading("Loading downlines...");
				const downlines = await loadDownlines(user);
				setDownlines(downlines);
			};
			fetchData();
		},
		[user]
	);

	useEffect(
		() => {
			let unsubscribe = null;
			if (!arrayIsEmpty(downlines)) {
				if (user.role === "Super Admin") {
					unsubscribe = loadAllSales(startDate, endDate, setSales, setLoading, setFSales, downlines);
					return;
				}
				unsubscribe = loadSales(startDate, endDate, setSales, setLoading, user, downlines, setFSales);
			}

			return () => {
				if (typeof unsubscribe === "function") unsubscribe();
			};
		},
		[downlines, startDate]
	);

	useEffect(
		() => {
			const salesByMonth = getSalesByMonth(fSales);
			setSalesByMonth(salesByMonth);
		},
		[sales, downlines]
	);

	useEffect(
		() => {
			if (downlines && fSales && sales) {
				setLoading(false);
			}
		},
		[downlines, fSales, sales]
	);

	return (
		<SalesContext.Provider
			value={{
				sales,
				setSales,
				downlines,
				setDownlines,
				loading,
				fSales,
				setFSales,
				salesByMonth,
				startDate,
				setStartDate,
				endDate,
				setEndDate,
				users,
				setUsers,
				projects,
				setProjects,
				locations,
				setLocations,
			}}
		>
			{children}
		</SalesContext.Provider>
	);
};
