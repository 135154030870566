import {Box, CircularProgress, Divider, Grid, IconButton, makeStyles, Typography} from "@material-ui/core";
import {Refresh} from "@material-ui/icons";
import {useContext, useEffect, useState} from "react";
import SalesTotalChart from "../components/SalesTotalChart";
import {SalesContext} from "../context/SalesContext";
import {month2String} from "../functions/date-format";
import {loadTotalSalesByRens} from "../functions/loadTotalSalesRens";
import {numberWithCommas} from "../functions/number-formating";
import back from "../assets/back-button.png";
import forward from "../assets/forward-button.png";
import withWidth from "@material-ui/core/withWidth";
import {InputContext} from "../context/InputContext";
import {getLastDay} from "../functions/getLastDay";

const useStyles = makeStyles((theme) => ({
	grid: {
		width: "100px",
		textAlign: "center",
	},
}));

const now = new Date();
const INITIAL_STATE = {
	startDate: "",
	endDate: "",
	month: now.getMonth() + 1,
	year: now.getFullYear(),
	displayStyle: "Table",
	showYearOnly: false,
	status: "Booked",
	booked: true,
	converted: false,
	customDate: false,
};

function TotalSalesPage({width}) {
	const classes = useStyles();
	const {fSales, downlines, loading} = useContext(SalesContext);
	const [totalSalesAndCommForRens, setTotalSalesAndCommForRens] = useState([]);
	const [salesToDisplay, setSalesToDisplay] = useState([]);
	const [state, setState] = useState(INITIAL_STATE);
	const [totalSales, setTotalSales] = useState();
	const [screen, setScreen] = useState("large");
	const {openDialog} = useContext(InputContext);

	useEffect(
		() => {
			if (width === "sm" || width === "xs") {
				setScreen("small");
			} else {
				setScreen("large");
			}
			// 'lg', 'md', 'sm', 'xl', 'xs'
		},
		[width]
	);

	useEffect(
		() => {
			const totalSalesAndCommForRens = loadTotalSalesByRens(
				state.startDate,
				state.endDate,
				state.status,
				fSales,
				downlines
			);
			setTotalSalesAndCommForRens(totalSalesAndCommForRens);

			let salesToDisplay = [];
			totalSalesAndCommForRens.forEach((sale, i) => {
				salesToDisplay.push({
					...sale,
					number: i + 1,
					renNameForTotalSales: sale.renName,
				});
			});
			setSalesToDisplay(salesToDisplay);
		},
		[fSales, downlines, state.startDate, state.endDate, state.status]
	);

	let chartFieldToDisplay = {
		number: "No.",
		renNameForTotalSales: "Name",
		totalSales: "Total Sales",
		barChart: "",
	};

	let itemWidth = {
		number: "10%",
		renNameForTotalSales: screen === "large" ? "200px" : "25%",
		totalSales: screen === "large" ? "80px" : "21%",
		barChart: "44%",
	};

	let barChartLengths = [];
	totalSalesAndCommForRens.forEach((sale, i) => {
		barChartLengths[i] = Math.ceil(
			sale.totalSales / totalSalesAndCommForRens[0].totalSales * (screen === "large" ? 55 : 20)
		);
	});

	useEffect(() => {
		window.scroll(0, 0);

		const today = new Date();
		let month = today.getMonth() + 1;
		let year = today.getFullYear();

		let startDate = year + "-" + month2String(month) + "-01";
		let endDate = year + "-" + month2String(month) + "-" + getLastDay(year, month);

		setState({
			...state,
			month: month,
			year: year,
			startDate: startDate,
			endDate: endDate,
		});
		// eslint-disable-next-line
	}, []);

	useEffect(
		() => {
			let totalSales = totalSalesAndCommForRens.reduce((acc, ren) => {
				return acc + ren.totalSales;
			}, 0);
			setTotalSales(totalSales);
		},
		[totalSalesAndCommForRens]
	);

	const addMonth = () => {
		let month = state.month;
		let year = state.year;

		month = month + 1;
		if (month === 13) {
			month = 1;
			year = year + 1;
		}

		let startDate = year + "-" + month2String(month) + "-01";
		let endDate = year + "-" + month2String(month) + "-" + getLastDay(year, month);

		setState({
			...state,
			month: month,
			year: year,
			startDate: startDate,
			endDate: endDate,
			showYearOnly: false,
			customDate: false,
		});
	};

	const substractMonth = () => {
		let month = state.month;
		let year = state.year;

		month = month - 1;
		if (month === 0) {
			year = year - 1;
			month = 12;
		}
		let startDate = year + "-" + month2String(month) + "-01";
		let endDate = year + "-" + month2String(month) + "-" + getLastDay(year, month);

		setState({
			...state,
			month: month,
			year: year,
			startDate: startDate,
			endDate: endDate,
			showYearOnly: false,
			customDate: false,
		});
	};

	const showTotalInYear = () => {
		let startDate = state.year + "-01-01";
		let endDate = state.year + "-12-31";
		setState({
			...state,
			startDate: startDate,
			endDate: endDate,
			showYearOnly: true,
		});
	};

	const handleClickStartDate = async () => {
		const response = await openDialog("Choose start date", "Choose a date", "Start Date", "", "date");
		if (response) {
			setState({...state, startDate: response, customDate: true});
		}
	};

	const handleClickEndDate = async () => {
		const response = await openDialog("Choose start date", "Choose a date", "Start Date", "", "date");
		if (response) {
			setState({...state, endDate: response, customDate: true});
		}
	};

	const dateLabel = () => {
		let display = state.month + " | " + state.year;
		if (state.showYearOnly) display = state.year;
		if (state.customDate) display = "Custom Date";

		return display;
	};

	return (
		<Box>
			<Grid container spacing={1} justify="left">
				<Grid item xs={12} md={7}>
					<Box display="flex" alignItems="center" justifyContent="center">
						<Grid onClick={() => setState({...state, displayStyle: "Table"})} className={classes.grid}>
							<Typography variant="h5">Table</Typography>
						</Grid>
						<Grid style={{marginRight: "15px", marginLeft: "15px"}}> | </Grid>
						<Grid onClick={() => setState({...state, displayStyle: "Chart"})} className={classes.grid}>
							<Typography variant="h5">Chart</Typography>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={12} md={7}>
					<Box display="flex" alignItems="center" justifyContent="center">
						<Grid
							style={{color: state.booked && "green"}}
							onClick={() =>
								setState({
									...state,
									status: "Booked",
									booked: true,
									converted: false,
								})}
							className={classes.grid}
						>
							<Typography variant="h5">Booked</Typography>
						</Grid>
						<Grid style={{marginRight: "15px", marginLeft: "15px"}}> | </Grid>
						<Grid
							style={{color: state.converted && "green"}}
							onClick={() =>
								setState({
									...state,
									status: "Converted",
									booked: false,
									converted: true,
								})}
							className={classes.grid}
						>
							<Typography variant="h5">Converted</Typography>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={12} md={7} align="center">
					<Typography variant="h5">Total Sales: RM {numberWithCommas(totalSales)}</Typography>
				</Grid>
				<Grid item xs={12} md={7}>
					<Box display="flex" alignItems="center" justifyContent="center">
						<Grid onClick={substractMonth} className={classes.grid}>
							<img width="25px" src={back} alt="back" />
						</Grid>
						<Grid
							style={{cursor: "pointer"}}
							onClick={showTotalInYear}
							style={{marginRight: "15px", marginLeft: "15px"}}
						>
							<Typography variant="h5">{dateLabel()}</Typography>
						</Grid>
						<Grid onClick={addMonth} className={classes.grid}>
							<img width="25px" src={forward} alt="forward" />
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={12} md={7} align="center">
					<Box display="flex" alignItems="center" justifyContent="center">
						<IconButton size="small" onClick={handleClickStartDate}>
							<Typography variant="h5">{state.startDate}</Typography>
						</IconButton>
						<Typography variant="body2" style={{marginLeft: "15px", marginRight: "15px"}}>
							to
						</Typography>
						<IconButton size="small" onClick={handleClickEndDate}>
							<Typography variant="h5">{state.endDate}</Typography>
						</IconButton>
					</Box>
				</Grid>
			</Grid>
			<Grid item>
				<Divider style={{margin: "10px"}} />
			</Grid>
			{loading && Loading(loading)}
			<Grid container justify="left">
				<Grid item xs={12} md={7}>
					{salesToDisplay.map((item, i) => (
						<SalesTotalChart
							sale={item}
							n={i}
							chartFieldToDisplay={chartFieldToDisplay}
							barChartLengths={barChartLengths}
							itemWidth={itemWidth}
						/>
					))}
				</Grid>
			</Grid>
		</Box>
	);
}

export default withWidth()(TotalSalesPage);

function Loading(loading) {
	return (
		<Box display="flex" alignItems="center">
			<CircularProgress />
			<Typography variant="body2" style={{margin: "15px"}}>
				{loading}
			</Typography>
		</Box>
	);
}
