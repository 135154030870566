import {firestore} from "../firebase/firebase-utils";

export async function getFirestoreData(collection, docId) {
	try {
		if (docId) {
			const doc = await firestore.collection(collection).doc(docId).get();
			if (doc.exists) {
				const data = {...doc.data(), id: doc.id};
				return data;
			} else {
				return null;
			}
		} else {
			const snapshot = await firestore.collection(collection).get();
			if (snapshot.empty) {
				return [];
			} else {
				const data = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
                return data
			}
		}
	} catch (error) {}
}
