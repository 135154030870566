import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";

// eslint-disable-next-line
const firebaseConfig_ELITEONE = {
  apiKey: "AIzaSyBKz0o7TIPwAXBuJa6q9nrw50AVbHJqDZ4",
  authDomain: "eliteone-react-sales-app.firebaseapp.com",
  databaseURL: "https://eliteone-react-sales-app.firebaseio.com",
  projectId: "eliteone-react-sales-app",
  storageBucket: "eliteone-react-sales-app.appspot.com",
  messagingSenderId: "293570880164",
  appId: "1:293570880164:web:3004eb0177ceaf6c3ffc31",
  measurementId: "G-M3954SWL64",
};

const firebaseConfig_LEGACY = {
  apiKey: "AIzaSyDbblrnFQ1-0z3tMbiREHtFApgGkH96C8I",
  authDomain: "elite-legacy-sales-app.firebaseapp.com",
  projectId: "elite-legacy-sales-app",
  storageBucket: "elite-legacy-sales-app.appspot.com",
  messagingSenderId: "683364956742",
  appId: "1:683364956742:web:298cdecba6580b4665a847",
  measurementId: "G-NN8L9XKWBN"
};

const firebaseConfig_DJC_SYSTEM_V2 = {
  apiKey: "AIzaSyDeLC5IknMvKtt-w00kJxBKIWi-_LouhEY",
  authDomain: "djc-sales-system-v2-f5ec6.firebaseapp.com",
  projectId: "djc-sales-system-v2-f5ec6",
  storageBucket: "djc-sales-system-v2-f5ec6.appspot.com",
  messagingSenderId: "671871174134",
  appId: "1:671871174134:web:c7d2cef5d06d937d48f3d7",
  measurementId: "G-LTM9P1RMZC",
};

let database = firebaseConfig_DJC_SYSTEM_V2;
const host = window.location.hostname;
console.log(host)
//Check if this is preview host
if(host === "legacyadmin.djcsystem.com"){
  database = firebaseConfig_LEGACY;
}

if(host === "admin.eliteonegroup.com"){
  database = firebaseConfig_ELITEONE;
}

if(host === "localhost"){
  database = firebaseConfig_ELITEONE;
}

firebase.initializeApp(database);

// For Local Firebase Emulator
// if (window.location.hostname === "localhost") {
//   firebase.functions().useEmulator("localhost", 5001);
//   firebase.firestore().useEmulator("localhost", 8080);
//   firebase.auth().useEmulator("http://localhost:9099")
//   console.log("Using Local Emulator");
// }

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();
export default firebase;
