import {firestore} from "../firebase/firebase-utils";
import {getFirestoreData} from "../functions/getFirestoreData";

export const findUplines = async (user) => {
	const getUpline = async (user) => {
		if (!user.immediateUplineFid) {
			return uplinesArray;
		}
		const doc = await firestore.collection("users").doc(user.immediateUplineFid).get();
		if (!doc.exists) {
			return uplinesArray;
		}
		const upline = {...doc.data(), id: doc.id};
		uplinesArray = uplinesArray.concat(upline);
		await getUpline(upline);

		return uplinesArray;
	};

	const first = await getFirestoreData("users", user.userFid);
	console.log(first);
	let uplinesArray = [first];
	const uplines = await getUpline(first);
	console.log(uplines);

	return uplines;
};
