import {getFirestoreData} from "./getFirestoreData";

export async function loadLocations() {
	try {
		const locations = getFirestoreData("locations");
		return locations;
	} catch (error) {
		console.log(error);
	}
}
