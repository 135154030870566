import { firestore } from "../firebase/firebase-utils";
import { mapSnapshot } from "./mapSnapshot";

export async function loadUsers() {
  try {
    const snapshot = await firestore.collection("users").orderBy("displayName").get();
    const users = mapSnapshot(snapshot);
    console.log(users);
    return users;
  } catch (error) {
    console.log(error);
  }
}
