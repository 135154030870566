import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components/macro";

import {Helmet} from "react-helmet-async";

import {
	Grid,
	Divider as MuiDivider,
	Typography as MuiTypography,
	CircularProgress,
	Box,
	withWidth,
	Button,
	TextField,
	InputAdornment,
	Icon,
	Grow,
	Fade,
} from "@material-ui/core";

import {spacing} from "@material-ui/system";
import {SalesContext} from "../context/SalesContext";
import {AuthContext} from "../context/AuthContext";
import FullDialog from "../components/FullDialog";
import UserPage from "./UserPage";
import {loadUsers} from "../functions/loadUsers";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function UsersPage({width}) {
	const {user} = useContext(AuthContext);
	const {loading, downlines, users, setUsers} = useContext(SalesContext);
	const [screen, setScreen] = useState("large");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [search, setSearch] = useState("");
	const [open, setOpen] = useState(false);
	const [member, setMember] = useState();
	const [members, setMembers] = useState([]);

	useEffect(
		() => {
			const fetchData = async () => {
				if (user.role === "Super Admin") {
					if (!users) {
						const allUsers = await loadUsers();
						setMembers(allUsers);
						setUsers(allUsers);
					} else {
						setMembers(users);
					}
				} else {
					setMembers(downlines);
				}
			};
			fetchData();
		},
		[user, downlines, users]
	);

	const handleChange = (e) => {
		setSearch(e.target.value);
		setPage(0);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClick = (downline) => {
		setMember(downline);
		setOpen(true);
	};

	let filteredUsers = members;
	filteredUsers.sort((a, b) => parseInt(b.userId || 0) - parseInt(a.userId || 0));

	filteredUsers = filteredUsers.filter((user) => {
		let display = false;

		if (user.displayName && user.displayName.toLowerCase().includes(search.toLowerCase())) display = true;
		if (user.userId && user.userId.toString().toLowerCase().includes(search.toLowerCase())) display = true;
		if (user.phoneNumber && user.phoneNumber.toString().toLowerCase().includes(search.toLowerCase()))
			display = true;

		return display;
	});

	const totalPages = Math.floor((filteredUsers.length - 1) / rowsPerPage) + 1;
	filteredUsers = filteredUsers.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

	return (
		<React.Fragment>
			{loading ? (
				<div>{Loading(loading)}</div>
			) : (
				<React.Fragment>
					<Helmet title="Default Dashboard" />
					<Grid justify="space-between" container spacing={screen === "large" ? 6 : 1}>
						<Grid item>
							<Typography variant="h3" gutterBottom>
								Users
							</Typography>
							<Typography variant="subtitle1">
								Welcome back, {user.displayName}! {" "}
								<span role="img" aria-label="Waving Hand Sign">
									👋
								</span>
							</Typography>
						</Grid>
					</Grid>
					<Divider my={6} />
					<Grid container>
						<Grid item xs={12} md={7}>
							<TextField
								variant="outlined"
								value={search}
								onChange={handleChange}
								label="search"
								fullWidth
								style={{marginBottom: "15px"}}
								InputProps={{
									endAdornment: search && (
										<InputAdornment position="end">
											<Icon style={{cursor: "pointer"}} onClick={() => setSearch("")}>
												clear
											</Icon>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xs={12} md={7} style={{marginBottom: "10px"}}>
							{PagesBox(page, setPage, totalPages)}
						</Grid>
					</Grid>

					<Grid container>
						<Grid item container xs={12} md={7} spacing={1} zeroMinWidth>
							<Grid item xs={2}>
								<Typography noWrap style={{fontWeight: "bold"}}>
									ID
								</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography noWrap style={{fontWeight: "bold"}}>
									Name
								</Typography>
							</Grid>
							<Grid item xs={2}>
								<Typography noWrap style={{fontWeight: "bold"}}>
									Phone
								</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography noWrap style={{fontWeight: "bold"}}>
									Upline
								</Typography>
							</Grid>
							{user.role === "Super Admin" && (
								<Grid xs={2}>
									<Typography noWrap style={{fontWeight: "bold"}}>
										chatId
									</Typography>
								</Grid>
							)}
							<Grid xs={12}>
								<Divider my={1} />
							</Grid>
							{filteredUsers.map((downline, i) => (
								<Grid container spacing={1}>
									<Grid item xs={2}>
										<Typography noWrap>{downline.userId}</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography
											noWrap
											style={{cursor: "pointer"}}
											onClick={() => handleClick(downline)}
										>
											{downline.displayName}
										</Typography>
									</Grid>
									<Grid item xs={2}>
										<Typography noWrap>{downline.phoneNumber}</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography noWrap>{downline.immediateUpline}</Typography>
									</Grid>
									{user.role === "Super Admin" && (
										<Grid item xs={2}>
											<Typography noWrap>{downline.chatId && downline.chatId}</Typography>
										</Grid>
									)}
								</Grid>
							))}
						</Grid>
					</Grid>
					<Grid item xs={12} md={7}>
						<Divider my={3} />
					</Grid>
					{PagesBox(page, setPage, totalPages)}
				</React.Fragment>
			)}
			<FullDialog
				open={open}
				handleClose={handleClose}
				title={"Edit user"}
				component={<UserPage ren={member} handleClose={handleClose} />}
			/>
		</React.Fragment>
	);
}

function Loading(loading) {
	return (
		<Box display="flex" alignItems="center">
			<CircularProgress />
			<Typography variant="body2" style={{margin: "15px"}}>
				{loading}
			</Typography>
		</Box>
	);
}

export default withWidth()(UsersPage);

function PagesBox(page, setPage, totalPages) {
	return (
		<Box display="flex" alignItems="center">
			<Button
				variant="outlined"
				color="inherit"
				disabled={page === 0}
				onClick={() => {
					setPage(page - 1);
				}}
			>
				Last Page
			</Button>
			<Typography variant="body2" style={{margin: "15px"}}>
				Page {page + 1} of {totalPages}
			</Typography>
			<Button
				variant="outlined"
				color="inherit"
				disabled={page + 1 === totalPages}
				onClick={() => {
					setPage(page + 1);
				}}
			>
				Next Page
			</Button>
		</Box>
	);
}
