import {firestore} from "../firebase/firebase-utils";
import {getAllUplinesFromSale} from "./getAllUplinesFromSale";
import {getUser} from "./getUser";
import {notification} from "./notification";
import firebase from "firebase";

export async function saveSale(sale) {
	try {
		const uplines = await getAllUplinesFromSale(sale);
		const teamIds = uplines.map((upline) => upline.id);
		const mySale = {...sale, teamIds};
		await firestore.collection("sales").doc(sale.id).update(mySale);
		notification("Updated", "Sale is updated", "success");
		console.log(sale.id + " sale is updated!");

		// Update Personal Sale Index
		sale.rens.forEach(async (ren) => {
			// load user from users
			const user = await getUser(ren.userFid);
			if (user) {
				await firestore
					.collection("users")
					.doc(user.id)
					.update({personalSalesIndex: firebase.firestore.FieldValue.arrayUnion(sale.id)});
				console.log("Sale Index updated for " + user.displayName);
			} else {
				console.log("No user found for " + ren.renName);
			}
		});
	} catch (error) {
		console.log(error);
	}
}
