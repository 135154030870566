import React, {useContext, useEffect} from "react";
import {useSelector} from "react-redux";
import {HelmetProvider, Helmet} from "react-helmet-async";
import DateFnsUtils from "@date-io/date-fns";

import {ThemeProvider} from "styled-components/macro";
import {create} from "jss";

import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {StylesProvider, ThemeProvider as MuiThemeProvider, jssPreset} from "@material-ui/core/styles";

import createTheme from "./theme";
import Routes, {SignInRoutes} from "./routes/Routes";

import {ListProvider} from "./context/ListContext";
import {ConfirmationProvider} from "./context/ConfirmationContext";
import {AuthContext} from "./context/AuthContext";
import {SalesProvider} from "./context/SalesContext";
import {InputProvider} from "./context/InputContext";

const jss = create({
	...jssPreset(),
	insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
	const theme = useSelector((state) => state.themeReducer);
	const {user} = useContext(AuthContext);

	let myTheme = createTheme(theme.currentTheme);

	useEffect(
		() => {
			console.log(user);
		},
		[user]
	);

	return (
		<React.Fragment>
			<HelmetProvider>
				<Helmet titleTemplate="%s | DJC Sales System" defaultTitle="DJC Sales System" />
				<StylesProvider jss={jss}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<MuiThemeProvider theme={myTheme}>
							<ThemeProvider theme={myTheme}>
								<ConfirmationProvider>
									<InputProvider>
										<SalesProvider user={user}>
											<ListProvider>{user ? <Routes /> : <SignInRoutes />}</ListProvider>
										</SalesProvider>
									</InputProvider>
								</ConfirmationProvider>
							</ThemeProvider>
						</MuiThemeProvider>
					</MuiPickersUtilsProvider>
				</StylesProvider>
			</HelmetProvider>
		</React.Fragment>
	);
}

export default App;
