import {firestore} from "../firebase/firebase-utils";
import {convertDate} from "./convertDate";
import {mapSnapshot} from "./mapSnapshot";

export async function loadAtlasSales(setAtlasSales) {
	try {
		return firestore.collection("atlasSales").onSnapshot((snapshot) => {
			const atlasSales = mapSnapshot(snapshot);
			atlasSales.sort((a, b) => {
				return new Date(convertDate(b.date)) - new Date(convertDate(a.date));
			});
			setAtlasSales(atlasSales);
		});
	} catch (error) {
		console.log(error);
	}
}
