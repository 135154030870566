import { firestore } from '../firebase/firebase-utils';

export async function getRenFromAtlasName(atlasRen) {
	try {
		const snapshot = await firestore.collection('users').where('atlasName', '==', atlasRen.name).get();
		if (snapshot.empty) {
			return { renName: '', renFid: '', id: '' };
		} else {
			const renName = snapshot.docs[0].data().displayName;
			const renFid = snapshot.docs[0].id;
			return { renName, renFid, id: renFid };
		}
	} catch (error) {
		console.log(error);
	}
}
