import React, {useContext} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {dashboardLayoutRoutes, authLayoutRoutes, presentationLayoutRoutes, protectedRoutes} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import PresentationLayout from "../layouts/Presentation";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Landing from "../pages/presentation/Landing";
import {AuthContext} from "../context/AuthContext";

const childRoutes = (Layout, routes) =>
	routes.map(({component: Component, guard, children, path}, index) => {
		const Guard = guard || React.Fragment;

		return children ? (
			children.map((element, index) => {
				const Guard = element.guard || React.Fragment;
				const ElementComponent = element.component || React.Fragment;

				return (
					<Route
						key={index}
						path={element.path}
						exact
						render={(props) => (
							<Layout>
								<Guard>
									<ElementComponent {...props} />
								</Guard>
							</Layout>
						)}
					/>
				);
			})
		) : Component ? (
			<Route
				key={index}
				path={path}
				exact
				render={(props) => (
					<Layout>
						<Guard>
							<Component {...props} />
						</Guard>
					</Layout>
				)}
			/>
		) : null;
	});

const Routes = () => {
	
	return (
		<Router>
			<Switch>
				{childRoutes(DashboardLayout, dashboardLayoutRoutes)}
				{childRoutes(DashboardLayout, protectedRoutes)}
				{childRoutes(AuthLayout, authLayoutRoutes)}
				{childRoutes(PresentationLayout, presentationLayoutRoutes)}
				<Route path="/" render={() => <Landing />} />
			</Switch>
		</Router>
	);
};

export function SignInRoutes() {
	return (
		<Router>
			<Switch>
				<Route exact path="/sign-in" render={() => <SignIn />} />
				<Route exact path="/sign-up" render={() => <SignUp />} />
				<Route path="/" render={() => <SignIn />} />
			</Switch>
		</Router>
	);
}

export default Routes;
