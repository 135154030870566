import { firestore } from '../firebase/firebase-utils';
import { notification } from './notification';
import firebase from 'firebase';
import { mapAtlasRenAndProject } from './mapAtlasNameAndProject';

export async function saveSaleFromAtlas(sale) {
	try {
		const collectionRef = firestore.collection('sales');
		await collectionRef.add(sale);
		notification('Success', 'Sale is added into database', 'success');

		//update sales parameter
		const doc = await firestore.collection("parameters").doc("parameters").get();
		if(doc.exists){
			await firestore.collection('parameters').doc('parameters').update({ numberOfSales: firebase.firestore.FieldValue.increment(1) });
		} else {
			await firestore.collection('parameters').doc('parameters').set({ numberOfSales: 1 });
		}

		await mapAtlasRenAndProject(sale);

		//update atlasSales mapped;
		await firestore.collection('atlasSales').doc(sale.atlasSaleId).update({ mapped: true });
		console.log('Updated atlasSale mapped = true');
	} catch (error) {
		console.log(error);
	}
}
