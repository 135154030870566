import React, {createContext, useEffect, useState} from "react";
import firebase, {firestore} from "../firebase/firebase-utils";
import {notification} from "../functions/notification";

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
	const [user, setUser] = useState(null);
	const [authUser, setAuthUser] = useState();

	useEffect(() => {
		// Local storage persistence for user object
		const userLocal = JSON.parse(localStorage.getItem("user"));
		const authUserLocal = JSON.parse(localStorage.getItem("authUser"));
		if (userLocal) {
			setUser(userLocal);
			setAuthUser(authUserLocal);
		}

		const getUser = async (querySnapshot, user) => {
			// Check whether the user exists in Firestore
			// If it is empty create a new user with user.email
			if (querySnapshot.empty) {
				const signUpDoc = await firestore.collection("appSignUps").doc(user.email).get();
				if (signUpDoc.exists) {
					const signUp = {...signUpDoc.data(), id: signUpDoc.id};
					await firestore.collection("users").doc(user.email).set({...signUp, profileCreated: true});
					const mappedUser = signUp;
					await firebase
						.firestore()
						.collection("users")
						.doc(user.email)
						.set({...mappedUser, profileCreated: true}, {merge: true});
					setUser(mappedUser);
					localStorage.setItem("user", JSON.stringify(mappedUser));
					console.log("Create new user in Firestore : " + user.email);
				} else {
					let mappedUser = {
						displayName: user.displayName ? user.displayName : user.email.split("@")[0],
						email: user.email,
						id: user.email,
					};
					await firebase.firestore().collection("users").doc(user.email).set(mappedUser, {merge: true});
					setUser(mappedUser);
					localStorage.setItem("user", JSON.stringify(mappedUser));
					console.log("Create new user in Firestore : " + user.email);
				}
			} else {
				// if it does exists, retrieve the data and put it into Global user state
				let userInFs = querySnapshot.docs[0].data();
				userInFs = {
					...userInFs,
					id: querySnapshot.docs[0].id,
					emailVerified: user.emailVerified,
				};

				// Check first time login
				if (!userInFs.profileCreated) {
					const signUpDoc = await firestore.collection("appSignUps").doc(user.email).get();
					if (signUpDoc.exists) {
						const signUp = {...signUpDoc.data(), id: signUpDoc.id};
						await firestore
							.collection("users")
							.doc(user.email)
							.set({...userInFs, ...signUp, profileCreated: true});
					}
				}

				setUser(userInFs);
				setAuthUser(user);
				localStorage.setItem("user", JSON.stringify(userInFs));
				localStorage.setItem("authUser", JSON.stringify(user));
			}
		};

		try {
			firebase.auth().onAuthStateChanged(async (user) => {
				// if there is no user, do nothing
				if (!user) {
					setUser(null);
					localStorage.removeItem("user");
					return;
				}

				// Get user from Firestore with email = user.email
				const querySnapshot = await firebase
					.firestore()
					.collection("users")
					.where("email", "==", user.email)
					.get();
				getUser(querySnapshot, user);
			});
		} catch (err) {
			console.log(err);
			notification("Opps", err.message, "warning");
		}
	}, []);

	return (
		<AuthContext.Provider
			value={{
				user,
				authUser,
				setUser,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
