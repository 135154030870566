import {firestore} from "../firebase/firebase-utils";
import { flattenSales } from "./flattenSales";
import {getFirestoreData} from "./getFirestoreData";
import {mapSnapshot} from "./mapSnapshot";

export async function loadAllSales(start, end, setSales, setLoading, setFSales, downlines) {
	try {
		setLoading("Loading all sales");
		return firestore
			.collection("sales")
			.where("date", ">=", new Date(start))
            .where("date", "<=", new Date(end))
			.orderBy("date", "desc")
			.onSnapshot((snapshot) => {
				if (snapshot.empty) {
                    setSales([]);
                    setFSales([]);
                    return;
                } else {
                    const sales = mapSnapshot(snapshot);
                    setSales(sales);
                    const fSales = flattenSales(sales, downlines);
                    setFSales(fSales);
                }
			});
	} catch (error) {
		console.log(error);
	}
}
