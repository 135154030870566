import {arrayIsEmpty} from "./arrayIsEmpty";

export function getTotalSales(sales) {
	if (arrayIsEmpty(sales)) return 0;
	let totalSales = 0;
	sales.forEach((sale) => {
		if (sale.renNetPrice) {
			if (sale.status === "Cancelled") return;
			totalSales = totalSales + parseFloat(sale.renNetPrice);
		}
	});
	return parseInt(totalSales);
}
