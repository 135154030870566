import FormDialog from "../components/FormDialog";
import React, {useState} from "react";
import {useRef} from "react";
import DateDialog from "../components/DateDialog";

export const InputContext = React.createContext();

export const InputProvider = ({children}) => {
	const [input, setInput] = useState();
	const [state, setState] = useState({title: "", text: "", label: ""});
	const [open, setOpen] = useState(false);
  const [openDate, setOpenDate] = useState(false);
	const [initialValue, setInitialValue] = useState();

	const handleSave = (value) => {
		setInput(value);
		setInitialValue("");
		awaitingPromiseRef.current.resolve(value);
	};

	const awaitingPromiseRef = useRef();

	// usage
	// const response = await openDialog(title, text, label, startValue, type)
	const openDialog = async (title, text, label, startValue, type="text") => {
		setState({
			title: title,
			text: text,
			label: label,
		});
		setInitialValue(startValue);
		if (type === "text") {
			setOpen(true);
		}
    if (type === "date") {
			setOpenDate(true);
		}

		return new Promise((resolve, reject) => {
			awaitingPromiseRef.current = {resolve, reject};
		});
	};

	return (
		<InputContext.Provider value={{input, setState, setInitialValue, setOpen, openDialog}}>
			{children}
			<FormDialog
				open={open}
				setOpen={setOpen}
				title={state.title}
				text={state.text}
				label={state.label}
				onSave={handleSave}
				initialValue={initialValue}
			/>
			<DateDialog
				open={openDate}
				setOpen={setOpenDate}
				title={state.title}
				text={state.text}
				label={state.label}
				onSave={handleSave}
				initialValue={initialValue}
			/>
		</InputContext.Provider>
	);
};
