import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	Icon,
	makeStyles,
	styled,
	TextField as MuiTextField,
	Typography,
} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import {spacing} from "@material-ui/system";
import {useContext, useState} from "react";
import {AuthContext} from "../context/AuthContext";
import {ConfirmationContext} from "../context/ConfirmationContext";
import {ListContext} from "../context/ListContext";
import {SalesContext} from "../context/SalesContext";
import {deleteUser} from "../functions/deleteUser";
import {loadUsers} from "../functions/loadUsers";
import {notification} from "../functions/notification";
import {saveUser} from "../functions/saveUser";

const TextField = styled(MuiTextField)(spacing);

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
}));

export default function UserPage({ren, handleClose}) {
	const classes = useStyles();
	const {user} = useContext(AuthContext);
	const [state, setState] = useState(ren);
	const [loading, setLoading] = useState(false);
	const {openGlobalList} = useContext(ListContext);
	const {downlines, setDownlines, users, setUsers} = useContext(SalesContext);
	const {openConfirmation} = useContext(ConfirmationContext);

	const handleChange = (e) => {
		const {name, value} = e.target;
		setState({...state, [name]: value});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!state.immediateUplineFid) {
			notification("Opps", "Please select Immediate Upline", "warning");
			return;
		}
		setLoading("Saving...");
		await saveUser(state);

		// update global state
		updateState(downlines, ren, state, setDownlines, users, setUsers);

		setLoading(false);
	};

	const handleClickEdit = async () => {
		setLoading(true);
		let allUsers = users;
		if (!users) {
			allUsers = await loadUsers();
			setUsers(allUsers);
		}
		const response = await openGlobalList(allUsers, "Select upline", "displayName", "face", false, true);
		if (response) {
			setState({...state, immediateUpline: response.displayName, immediateUplineFid: response.id});
		}
		setLoading(false);
	};

	const handleDeleteUser = async () => {
		const response = await openConfirmation("Delete user", "Are you sure you want to delete the user?");
		if (response) {
			await deleteUser(ren);
			updateStateOnDelete(downlines, ren, setDownlines, users, setUsers);
			handleClose();
		}
	};

	return (
		<Box m={6}>
			<Grid container>
				<form style={{width: "100%"}} onSubmit={handleSubmit}>
					<Grid container item xs={12} md={7} alignItems="center" spacing={1}>
						<Grid item xs={12}>
							<TextField
								m={1}
								variant="outlined"
								label={"ID"}
								value={state.userId}
								name="userId"
								onChange={handleChange}
								fullWidth
								disabled
							/>
						</Grid>
						{user.role === "Super Admin" && (
							<Grid item xs={12}>
								<TextField
									m={1}
									variant="outlined"
									label={"Fid"}
									value={state.id}
									name="id"
									onChange={handleChange}
									fullWidth
									disabled
								/>
							</Grid>
						)}
						<Grid item xs={12}>
							<TextField
								m={1}
								variant="outlined"
								label={"Name"}
								value={state.displayName}
								name="displayName"
								onChange={handleChange}
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								m={1}
								variant="outlined"
								label={"Atlas Name"}
								value={state.atlasName}
								name="atlasName"
								onChange={handleChange}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								m={1}
								variant="outlined"
								label={"Email"}
								value={state.email}
								name="email"
								onChange={handleChange}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								m={1}
								variant="outlined"
								label={"Phone"}
								value={state.phoneNumber}
								name="phoneNumber"
								onChange={handleChange}
								fullWidth
							/>
						</Grid>
						<Grid item xs={11}>
							<TextField
								m={1}
								variant="outlined"
								label={"Immediate Upline"}
								value={state.immediateUpline}
								name="immediateUpline"
								onChange={handleChange}
								fullWidth
								InputLabelProps={{shrink: true}}
								disabled
							/>
						</Grid>
						<Grid item xs={1}>
							<Box ml={1} alignItems="center" justifyContent="center">
								<Icon style={{cursor: "pointer"}} onClick={handleClickEdit}>
									edit
								</Icon>
							</Box>
						</Grid>
						{user.role === "Super Admin" && (
							<Grid item xs={12}>
								<TextField
									m={1}
									variant="outlined"
									label={"Immediate Upline Id"}
									value={state.immediateUplineFid}
									name="immediateUplineFid"
									onChange={handleChange}
									fullWidth
									InputLabelProps={{shrink: true}}
									disabled
								/>
							</Grid>
						)}
						{user.role === "Super Admin" && (
							<Grid item xs={12}>
								<TextField
									m={1}
									variant="outlined"
									label={"Chat Id"}
									value={state.chatId && state.chatId}
									name="chatId"
									onChange={handleChange}
									fullWidth
								/>
							</Grid>
						)}
						<Grid item xs={12}>
							<Box m={1}>
								<Button variant="contained" color="primary" type="submit">
									Update
								</Button>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Box m={1}>
								<Button
									variant="outlined"
									size="small"
									style={{color: red[500]}}
									onClick={handleDeleteUser}
								>
									Delete User
								</Button>
							</Box>
						</Grid>
						<Backdrop className={classes.backdrop} open={loading}>
							<CircularProgress color="inherit" />{" "}
							<Typography style={{marginLeft: "15px"}}>{loading}</Typography>
						</Backdrop>
					</Grid>
				</form>
			</Grid>
		</Box>
	);
}

function updateStateOnDelete(downlines, ren, setDownlines, users, setUsers) {
	const newDownlines = downlines.filter((downline) => downline.id !== ren.id);
	setDownlines(newDownlines);

	if (users) {
		const newUsers = users.filter((user) => user.id !== ren.id);
		setUsers(newUsers);
	}
}

function updateState(downlines, ren, state, setDownlines, users, setUsers) {
	const index = downlines.findIndex((doc) => doc.id === ren.id);
	let newDownlines = [...downlines];
	newDownlines[index] = state;
	setDownlines(newDownlines);

	if (users) {
		const index = users.findIndex((doc) => doc.id === ren.id);
		let newUsers = [...users];
		newUsers[index] = state;
		setUsers(newUsers);
	}
}
