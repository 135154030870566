import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import {Button as MuiButton, Menu, MenuItem} from "@material-ui/core";

import {Loop as LoopIcon, FilterList as FilterListIcon} from "@material-ui/icons";

import {spacing} from "@material-ui/system";
import moment from "moment";

const Button = styled(MuiButton)(spacing);

function Actions({now, setNow}) {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (e) => {
		const days = e.target.value;
		console.log(days);
		const today = new Date();
		const newNow = new Date(today - days * 86400400);
		setNow(new Date(newNow));
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<Button variant="contained" color="secondary" onClick={handleClick}>
				{moment(now).format("ddd DD MMM YYYY")}
			</Button>
			<Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				<MenuItem onClick={handleClose} value={0}>
					Today
				</MenuItem>
				<MenuItem onClick={handleClose} value={7}>
					Last week
				</MenuItem>
				<MenuItem onClick={handleClose} value={14}>
					Last 2 weeks
				</MenuItem>
				<MenuItem onClick={handleClose} value={21}>
					Last 3 weeks
				</MenuItem>
				<MenuItem onClick={handleClose} value={28}>
					Last 4 weeks
				</MenuItem>
			</Menu>
		</React.Fragment>
	);
}

export default Actions;
