import { firestore } from "../firebase/firebase-utils";

export async function getUserFromFS(email) {
  try {
    const snapshot = await firestore
      .collection("users")
      .where("email", "==", email)
      .get();
    const user = { ...snapshot.docs[0].data(), id: snapshot.docs[0].id };
    return user;
  } catch (error) {
    console.log(error);
  }
}
