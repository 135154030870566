export function flattenSales(sales, downlines) {
	try {
		const downlineIds = downlines.map((user) => user.id);
		let fSales = [];
		sales.forEach((sale) => {
			sale.rens.forEach((ren) => {
				if (!downlineIds.includes(ren.userFid)) return;
				const fSale = {
					...sale,
					ren: ren,
					renSpaPrice: parseFloat(ren.renPercentage) / 100 * parseFloat(sale.spaPrice),
					renNetPrice: parseFloat(ren.renPercentage) / 100 * parseFloat(sale.netPrice),
				};
				fSales.push(fSale);
			});
		});
		return fSales;
	} catch (error) {
		console.log(error);
	}
}
	