import React from "react";
import styled, {withTheme} from "styled-components/macro";

import {Card as MuiCard, CardContent, CardHeader, IconButton} from "@material-ui/core";

import {spacing} from "@material-ui/system";

import "../../vendor/roundedBarCharts";
import {Bar} from "react-chartjs-2";

import {MoreVertical} from "react-feather";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
	height: 340px;
	width: 100%;
`;

const BarChart = ({theme, salesByMonth, year, title, label}) => {
	const firstDatasetColor = theme.palette.secondary.main;
	const secondDatasetColor = theme.palette.type === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.15)";

	const datas = [];
	for (let i = 1; i <= 12; i++) {
		let key = "";
		if (i < 10) {
			key = `${year}0${i}`;
		} else {
			key = `${year}${i}`;
		}
		if (salesByMonth[key]) {
			datas.push(parseInt(salesByMonth[key]));
		} else {
			datas.push(0);
		}
	}

	const data = {
		labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
		datasets: [
			{
				label: label,
				backgroundColor: firstDatasetColor,
				borderColor: firstDatasetColor,
				hoverBackgroundColor: firstDatasetColor,
				hoverBorderColor: firstDatasetColor,
				data: datas,
				barPercentage: 0.5,
				categoryPercentage: 0.5,
			},
		],
	};

	const options = {
		maintainAspectRatio: false,
		cornerRadius: 2,
		legend: {
			display: false,
		},
		tooltips: {
			callbacks: {
				label: function(tooltipItem, data) {
					var value = data.datasets[0].data[tooltipItem.index];
					if (parseInt(value) >= 1000) {
						return "RM" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					} else {
						return "RM" + value;
					}
				},
			},
			intersect: false,
		},
		scales: {
			yAxes: [
				{
					ticks: {
						userCallback: function(value) {
							return Number(value / 1000000).toLocaleString("en") + " mil";
						},
						fontColor: theme.palette.text.secondary,
					},
					display: true,
					borderDash: [5, 5],
					gridLines: {
						color: "rgba(0,0,0,0.0375)",
						fontColor: "#fff",
					},
				},
			],
			xAxes: [
				{
					stacked: true,
					gridLines: {
						color: "transparent",
					},
					ticks: {
						fontColor: theme.palette.text.secondary,
					},
				},
			],
		},
	};

	return (
		<Card mb={1}>
			<CardHeader
				
				title={title}
			/>

			<CardContent>
				<ChartWrapper>
					<Bar data={data} options={options} />
				</ChartWrapper>
			</CardContent>
		</Card>
	);
};

export default withTheme(BarChart);
