import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./mocks";

import {Provider} from "react-redux";
import store from "./redux/store/index";
import ReactNotifications from "react-notifications-component";

import {AuthProvider} from "./context/AuthContext";

ReactDOM.render(
	<React.Fragment>
		<ReactNotifications />
		<Provider store={store}>
			<AuthProvider>
					<App />
			</AuthProvider>
		</Provider>
	</React.Fragment>,
	document.getElementById("root")
);
