import { mapSnapshot } from "../functions/mapSnapshot";
import { firestore } from "../firebase/firebase-utils";

export async function populateDownlines() {
	const usersSnapshot = await firestore.collection("users").get();
	let users = mapSnapshot(usersSnapshot);

	const masterArray = await Promise.all(
		users.map(async (user, i) => {
			const downlines = await findDownlines(user);
			return {
				updatedAt: new Date(),
				userFid: user.id,
				userName: user.displayName,
				downlines: downlines,
			};
		})
	);
	console.log(masterArray.length + " users in Database");
	masterArray.forEach(async (downline) => {
		if (downline.downlines.length > 50) console.log(downline);
		await firestore.collection("downlines").doc(downline.userFid).set(downline);
		console.log("Updated downlines to Firestore");
	});
}

async function findDownlines(user) {
	let downlinesArray = [];
	async function getDownlines(user) {
		const snapshot = await firestore.collection("users").where("immediateUplineFid", "==", user.id).get();
		if (snapshot.empty) {
			return null;
		}
		const downlines = mapSnapshot(snapshot);

		if (downlines) {
			const downlinesNames = await Promise.all(
				downlines.map(async (downline) => {
					await getDownlines(downline);
					return { userFid: downline.id, userName: downline.displayName };
				})
			);
			downlinesArray = downlinesArray.concat(downlinesNames);
			return downlinesArray;
		}
	}
	// Put ownself as first downline
	downlinesArray = [ { userFid: user.id, userName: user.displayName } ];
	const downlines = await getDownlines(user);

	if (downlines) {
		return downlines;
	} else {
		return [ { userFid: user.id, userName: user.displayName } ];
	}
}
