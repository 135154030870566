import {arrayIsEmpty} from "./arrayIsEmpty";

export function getDownlines(user, downlines) {
    console.log(user)
    console.log(downlines)
	if (!user || arrayIsEmpty(downlines)) return [];

	let array = [user];
	const findDownlines = (user) => {
		const myDownlines = downlines.filter((downline) => downline.immediateUplineFid === user.id);
		if (!myDownlines) return;
		array = array.concat(myDownlines);
		myDownlines.forEach((downline) => {
			findDownlines(downline);
		});
	};
	findDownlines(user);

	return array;
}
