import {firestore} from "../firebase/firebase-utils";
import {notification} from "./notification";

export async function sendClouldMail(emailToBeSent) {
    console.log(emailToBeSent)
	firestore
		.collection("cloudMails")
		.add(emailToBeSent)
		.then(() => {
			notification("Success", "Emails have been sent", "success");
			console.log("Email sent!");
		})
		.catch((err) => {
			console.log(err.message);
			notification("Error", err.message, "warning");
		});
}
