import {convertDate} from "./convertDate";

export function loadTotalSalesByRens(dateStart, dateEnd, status, teamSales, downlines) {
	if (!teamSales || !teamSales.length) {
		console.log("No Sales loaded!");

		return [];
	}
	if (!downlines || !downlines.length) {
		console.log("No downlines loaded");

		return [];
	}

	let start = new Date(dateStart);
	start = start.setHours(0, 0, 0, 0);
	let end = new Date(dateEnd);
	end = end.setHours(23, 59, 59, 59);

	const getTotalSalesAndComm = (ren, status) => {
		let salesForRen = teamSales.filter((teamSale) => teamSale.ren.userFid === ren.id);
		salesForRen = salesForRen.filter((sale) => {
			if (convertDate(sale.date) >= start && convertDate(sale.date) <= end) {
				return true;
			} else {
				return false;
			}
		});

		let totalSales = 0;
		let totalComm = 0;
		switch (status) {
			case "Booked":
				totalSales = salesForRen.reduce((accumulativeSales, sale) => {
					if (sale.status !== "Cancelled") {
						return accumulativeSales + sale.renNetPrice;
					} else {
						return accumulativeSales;
					}
				}, 0);

				// totalComm = salesForRen.reduce((accumulativeComm, sale) => {
				// 	if (sale.status !== "Cancelled") {
				// 		return accumulativeComm + sale.commForRen;
				// 	} else {
				// 		return accumulativeComm;
				// 	}
				// }, 0);
				break;
			case "Converted":
				totalSales = salesForRen.reduce((accumulativeSales, sale) => {
					if (sale.status === "Converted") {
						return accumulativeSales + sale.renNetPrice;
					} else {
						return accumulativeSales;
					}
				}, 0);

				// totalComm = salesForRen.reduce((accumulativeComm, sale) => {
				// 	if (sale.status === "Converted") {
				// 		return accumulativeComm + sale.commForRen;
				// 	} else {
				// 		return accumulativeComm;
				// 	}
				// }, 0);
				break;
			default:
		}
		return {totalSales, totalComm};
	};

	let totalSalesAndCommForRens = [];
	downlines.forEach((downline) => {
		const {totalSales, totalComm} = getTotalSalesAndComm(downline, status);
		if (!totalSales) {
			return null;
		}
		totalSalesAndCommForRens.push({
			renName: downline.displayName,
			totalSales: totalSales,
			totalComm: totalComm,
		});
	});
	totalSalesAndCommForRens.sort((a, b) => b.totalSales - a.totalSales);

	return totalSalesAndCommForRens;
}
